import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";

import JEEAdvForm from "./Components/JEEAdvForm/JEEAdvForm";
import JEEForm from "./Components/JEEForm/JEEForm";
import CETForm from "./Components/CETForm/CETForm";
import { HelmetProvider } from "react-helmet-async";
import NotFound from "./Components/NotFound/NotFound";
import NewsAll from "./Components/NewsAll/NewsAll";
import AdmissionConsultingPage from "./Components/AdmissionConsulting/AdmissionConsultingPage";
import PlanPage from "./Components/Plan/Plan";
import LoginPage from "./Components/Login/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import SuccessPage from "./Components/successpage/SuccessPage";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <BrowserRouter basename="/">
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route
              path="/jee-advanced-college-predictor/"
              component={JEEAdvForm}
              exact
            />

            <Route
              path="/jee-main-college-predictor/"
              component={JEEForm}
              exact
            />
            <Route
              path="/mht-cet-college-predictor/"
              component={CETForm}
              exact
            />
            <Route
              path="/admissionconsulting"
              component={AdmissionConsultingPage}
              exact
            />
            <Route path="/dashboard/" component={Dashboard} exact />
            <Route path="/plan/" component={PlanPage} exact />
            <Route path="/login/" component={LoginPage} exact />
            <Route path="/orderstatus/" component={SuccessPage} exact />
            <Route path="/news/" component={NewsAll} exact />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
