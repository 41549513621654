import React, { Component } from "react";
import MarqueePage from "../Marquee/MarqueePage";
import "./HomePage.css";
import graphic1 from "../../Images/graphic1.png";
import News from "../News/News";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import ContactUs from "../ContactUs/ContactUs";
import AboutUs from "../AboutUs/AboutUs";
import homepageimage from "../../Images/Home-Page-Thumbnail.png";
import CollegePredictor from "../CollegePredictor/CollegePredictor";
import { Helmet } from "react-helmet-async";
import AdmissionConsulting from "../Consulting/AdmissionConsultingHomepage";
class HomePage extends Component {
  render() {
    return (
      <div className="">
        <Helmet>
          <meta name="robots" content="index,follow" />

          <title>
            Graduhat | College Predictor, Admission Consulting & News
          </title>
          <meta
            name="description"
            content="Get College Predictions for JEE Main, JEE Advanced, MHT-CET, latest News, Admission Consulting and much more"
          />
          <meta
            name="keywords"
            content="Graduhat,College Predictor,Engineering College Predictor,Latest News,JEE Mains,JEE Advanced,MHT-CET,josaa,IIT,NIT,IIIT,GFTI,NTA"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Graduhat | College Predictor, Admission Consulting & News"
          />
          <meta
            property="og:description"
            content="Get College Predictions for JEE Main, JEE Advanced, MHT-CET, latest News, Admission Consulting and much more"
          />
          <meta property="og:url" content="https://www.graduhat.com/" />
          <meta property="og:image" content={homepageimage} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@graduhat" />
          <meta name="twitter:creator" content="@graduhat" />
          <meta
            name="twitter:title"
            content="Graduhat | College Predictor, Admission Consulting & News"
          />
          <meta
            name="twitter:description"
            content="Get College Predictions for JEE Main, JEE Advanced, MHT-CET, latest News, Admission Consulting and much more"
          />
          <meta name="twitter:image" content={homepageimage} />
        </Helmet>
        <Navbar isForm={false} />
        <MarqueePage />
        <hr className="mt-0" />
        <div className="row justify-content-center HomePage_secondRow">
          <div className="col-12 col-md-5 HomePage_col">
            <h1 className="HomePage_pHeader">
              Your first stepping stone towards Graduation
            </h1>
            <p className="HomePage_pSubHeader">
              One-stop place to clear up all your confusions around Admissions
              and Options/Choice form filling.
              <br />
              Get accurate College Predictions, the latest news for Exams,
              Applications, Option/Choice form filling, and much more 
              <b>without login or contact details.</b>
            </p>
          </div>
          <div className="col-12 col-md-5">
            <img
              src={graphic1}
              className="HomePage_graphic img-fluid"
              alt="graphic"
            />
          </div>
        </div>
        <hr />
        <div id="collegepredictor">
          <CollegePredictor />
        </div>
        <div id="news">
          <News />
        </div>

        <div id="admissionconsulting">
          <AdmissionConsulting />
        </div>
        <div id="contactus">
          <ContactUs />
        </div>
        <div id="aboutus">
          <AboutUs />
        </div>
        <a href="/news" hidden></a>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default HomePage;
