import React, { Component } from "react";
import "./Dashboard.css";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet-async";
import backgd from "../../Images/bk.png";
import Tawk from "./tawk";
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props?.location?.state || [],
    };
  }

  render() {
    if (this.state.data.length === 0) {
      this.props.history.replace("/login");
      return null;
    }
    return (
      <div
        style={{
          backgroundImage: `url(${backgd})`,
        }}
        className="Dashboard_Page"
      >
        <Helmet>
          <meta name="robots" content="index,follow" />
          <title>Dashboard</title>
        </Helmet>
        <Tawk
          fullName={this.state.data[0]}
          email={this.state.data[1]}
          hashCode={this.state.data[2]}
        />
        <div className="row Dashboard_row align-items-center justify-content-center  text-left">
          <div className="col-11 col-md-6">
            <h1>Hello {this.state.data[3]}, Welcome to Graduhat!</h1>
            <p>Please wait while your chat is loaded in bottom right corner</p>

            <div className="row Dashboard_row justify-content-start mt-5 text-left">
              <div className="col-10 col-md-10 pl-0 Dashboard_colBorder">
                Number of video calls left in your subscribed plan:
              </div>
              <div className="col-1 Dashboard_colBorder">
                <p>{this.state.data[4]}</p>
              </div>
            </div>
            <div className="mt-3">
              <p>
                To schedule video call message us in the chat window or email us
                at{" "}
                <a
                  className="DashboardPage_links"
                  href="mailto:support@graduhat.com"
                >
                  support@graduhat.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="Dashboard_footerText text-left">
          <p>
            Got a minute? Please help us review our service by filling this{" "}
            <a href="https://forms.gle/3JWWeXsiH4Xz1ifB9">survey form</a>, so that we can improve in future.
          </p>
        </div>
      </div>
    );
  }
}
