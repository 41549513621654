/*
for single select
{
  name:"",
  value:""
}

for multi select
{
  label:"",
  value:""
}
*/
export const categories = [
  {
    name: "OPEN",
    value: "OPEN",
  },
  {
    name: "OBC-NCL",
    value: "OBC-NCL",
  },
  {
    name: "EWS",
    value: "EWS",
  },
  {
    name: "SC",
    value: "SC",
  },
  {
    name: "ST",
    value: "ST",
  },
  {
    name: "OPEN (PwD)",
    value: "OPEN (PwD)",
  },
  {
    name: "OBC-NCL (PwD)",
    value: "OBC-NCL (PwD)",
  },
  {
    name: "EWS (PwD)",
    value: "EWS (PwD)",
  },
  {
    name: "SC (PwD)",
    value: "SC (PwD)",
  },
  {
    name: "ST (PwD)",
    value: "ST (PwD)",
  },
];

export const archPlanBranched = [
  {
    label: "Planning (4 Years, B.Plan)",
    value: "16",
  },
  {
    label: "Architecture (5 Years, B.Arch)",
    value: "12",
  },
];
export const branches = [
  {
    label: "Bio Technology (4 Years, B.Tech)",
    value: "1",
  },
  {
    label: "Chemical Engg. (4 Years, B.Tech)",
    value: "2",
  },
  {
    label: "Civil Engg. (4 Years, B.Tech)",
    value: "3",
  },
  {
    label: "Computer Science and Engg. (4 Years, B.Tech)/ Computer Engg. (4 Years, B.Tech)/ Computer Science (4 Years, B.Tech)",
    value: "4,38,56",
  },
  {
    label: "Electrical Engg. (4 Years, B.Tech)",
    value: "5",
  },
  {
    label: "Electronics and Communication Engg. (4 Years, B.Tech)/ Electronics and Telecommunication Engg. (4 Years, B.Tech)",
    value: "6,49",
  },
  {
    label: "Industrial and Production Engg. (4 Years, B.Tech)",
    value: "7",
  },
  {
    label: "Information Technology (4 Years, B.Tech)",
    value: "8",
  },
  {
    label: "Instrumentation and Control Engg. (4 Years, B.Tech)",
    value: "9",
  },
  {
    label: "Mechanical Engg. (4 Years, B.Tech)",
    value: "10",
  },
  {
    label: "Textile Technology (4 Years, B.Tech)",
    value: "11",
  },
  {
    label: "Metallurgical and Materials Engg. (4 Years, B.Tech)",
    value: "13",
  },
  {
    label: "Materials Science and Metallurgical Engg. (4 Years, B.Tech)",
    value: "14",
  },
  {
    label:
      "Mathematics and Data Science (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "15",
  },
  {
    label: "Production and Industrial Engg. (4 Years, B.Tech)",
    value: "17",
  },
  {
    label: "Biotechnology and Biochemical Engg. (4 Years, B.Tech)",
    value: "18",
  },
  {
    label:
      "Chemistry (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    value: "19",
  },
  {
    label:
      "Computational Mathematics (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "20",
  },
  {
    label: "Electronics and Instrumentation Engg. (4 Years, B.Tech)",
    value: "21",
  },
  {
    label: "Engg. Physics (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "22",
  },
  {
    label:
      "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    value: "23",
  },
  {
    label:
      "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    value: "24",
  },
  {
    label: "Production Engg. (4 Years, B.Tech)",
    value: "25",
  },
  {
    label: "Electrical and Electronics Engg. (4 Years, B.Tech)",
    value: "26",
  },
  {
    label: "Engg. Physics (4 Years, B.Tech)",
    value: "27",
  },
  {
    label: "Materials Science and Engg. (4 Years, B.Tech)",
    value: "28",
  },
  {
    label: "Biotechnology (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "29",
  },
  {
    label: "Chemical Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "30",
  },
  {
    label: "Chemistry (5 Years, Integrated Master of Science)",
    value: "31",
  },
  {
    label:
      "Computer Science and Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "32",
  },
  {
    label:
      "Electronics and Communication Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "33",
  },
  {
    label: "Mathematics and Computing (4 Years, B.Tech)",
    value: "34",
  },
  {
    label: "Artificial Intelligence (4 Years, B.Tech)",
    value: "35",
  },
  {
    label: "Mining Engg. (4 Years, B.Tech)",
    value: "36",
  },
  {
    label: "Bio Medical Engg. (4 Years, B.Tech)",
    value: "37",
  },
  {
    label: "Ceramic Engg. (4 Years, B.Tech)",
    value: "39",
  },
  {
    label:
      "Ceramic Engg. and M.Tech Industrial Ceramic (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "40",
  },
  {
    label: "Food Process Engg. (4 Years, B.Tech)",
    value: "41",
  },
  {
    label: "Industrial Design (4 Years, B.Tech)",
    value: "42",
  },
  {
    label: "Life Science (5 Years, Integrated Master of Science)",
    value: "43",
  },
  {
    label: "Mathematics (5 Years, Integrated Master of Science)",
    value: "44",
  },
  {
    label:
      "Metallurgical and Materials Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "45",
  },
  {
    label: "Mining Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: "46",
  },
  {
    label: "Physics (5 Years, Integrated Master of Science)",
    value: "47",
  },
  {
    label: "Aerospace Engg. (4 Years, B.Tech)",
    value: "48",
  },
  {
    label: "Metallurgy and Materials Engg. (4 Years, B.Tech)",
    value: "50",
  },
  {
    label:
      "Integrated B. Tech.(IT) and M. Tech (IT) (5 Years, Integrated B. Tech. and M. Tech.)",
    value: "51",
  },
  {
    label:
      "Integrated B. Tech.(IT) and MBA (5 Years, Integrated B. Tech. and MBA)",
    value: "52",
  },
  {
    label: "Information Technology- Business Informatics (4 Years, B.Tech)",
    value: "53",
  },
  {
    label:
      "Computer Science and Engg. with Major in Artificial Intelligence (4 Years, B.Tech)",
    value: "54",
  },
  {
    label: "Smart Manufacturing (4 Years, B.Tech)",
    value: "55",
  },
  {
    label: "Computer Science and Artificial Intelligence (4 Years, B.Tech)",
    value: "57",
  },
  {
    label: "Computer Science and Business (4 Years, B.Tech)",
    value: "58",
  },
  {
    label: "Data Science and Artificial Intelligence (4 Years, B.Tech)",
    value: "59",
  },
  {
    label: "Artificial Intelligence and Data Science (4 Years, B.Tech)",
    value: "60",
  },
  {
    label:
      "Electronics and Communication Engg. with specialization in Design and Manufacturing (4 Years, B.Tech)",
    value: "61",
  },
  {
    label:
      "Mechanical Engg. with specialization in Design and Manufacturing (4 Years, B.Tech)",
    value: "62",
  },
  {
    label:
      "Computer Science and Engg. with specialization in Cyber Security (4 Years, B.Tech)",
    value: "63",
  },
  {
    label:
      "Computer Science and Engg. (with Specialization of Data Science and Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    value: "64",
  },
  {
    label:
      "Electronics and Communication Engg. (with Specialization of Embedded Systems and Internet of Things) (4 Years, B. Tech / B. Tech (Hons.))",
    value: "65",
  },
  {
    label: "Mechatronics Engg. (4 Years, B.Tech)",
    value: "66",
  },
  {
    label: "Agricultural Engg. (4 Years, B.Tech)",
    value: "67",
  },
  {
    label: "Food Technology (5 Years, Integrated Master of Science)",
    value: "68",
  },
  {
    label: "Mathematics and Computing (5 Years, Integrated Master of Science)",
    value: "69",
  },
  {
    label:
      "Quantitative Economics & Data Science (5 Years, Integrated Master of Science)",
    value: "70",
  },
  {
    label: "Carpet and Textile Technology (4 Years, B.Tech)",
    value: "71",
  },
  {
    label: "Electronics System Engg. (4 Years, B.Tech)",
    value: "72",
  },
  {
    label: "Food Technology (4 Years, B.Tech)",
    value: "73",
  },
  {
    label: "Food Engg. and Technology (4 Years, B.Tech)",
    value: "74",
  },
  {
    label: "Electrical and Instrumentation Engg. (4 Years, B.Tech)",
    value: "75",
  },
  {
    label: "Computer Science (5 Years, Integrated M.Tech)",
    value: "76",
  },
  {
    label:
      "Computer Science & Engg. (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    value: "77",
  },
  {
    label:
      "Electronics & Communication Engg. (5 Years, B.Tech. + M.Tech./MS (Dual Degree))",
    value: "78",
  },
  {
    label: "Instrumentation Engg. (4 Years, B.Tech)",
    value: "79",
  },
  {
    label: "Food Technology and Management (4 Years, B.Tech)",
    value: "80",
  },
  {
    label: "Handloom and Textile Technology (4 Years, B.Tech)",
    value: "81",
  },
  {
    label:
      "Computer Science Engg. (Artificial Intelligence) (4 Years, B. Tech / B. Tech (Hons.))",
    value: "82",
  },
  {
    label:
      "Computer Science Engg. (Data Science) (4 Years, B. Tech / B. Tech (Hons.))",
    value: "83",
  },
  {
    label: "Chemical Engg. (5 Years, Integrated Masters in Technology)",
    value: "84",
  },
  {
    label: "Biomedical Engg. (4 Years, B.Tech)",
    value: "85",
  },
  {
    label: "Energy Engg. (4 Years, B.Tech)",
    value: "86",
  },
];
export const stateOfDomicile = [
  {
    name: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  {
    name: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },

  {
    name: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    name: "Assam",
    value: "Assam",
  },
  {
    name: "Bihar",
    value: "Bihar",
  },
  {
    name: "Chandigarh",
    value: "Chandigarh",
  },
  {
    name: "Chhattisgarh",
    value: "Chhattisgarh",
  },
  {
    name: "Dadra and Nagar Haveli and Daman and Diu",
    value: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    name: "Delhi",
    value: "Delhi",
  },
  {
    name: "Goa",
    value: "Goa",
  },
  {
    name: "Gujarat",
    value: "Gujarat",
  },
  {
    name: "Haryana",
    value: "Haryana",
  },
  {
    name: "Himachal Pradesh",
    value: "Himachal Pradesh",
  },
  {
    name: "Jammu and Kashmir",
    value: "Jammu and Kashmir",
  },
  {
    name: "Jharkhand",
    value: "Jharkhand",
  },
  {
    name: "Karnataka",
    value: "Karnataka",
  },
  {
    name: "Kerala",
    value: "Kerala",
  },

  {
    name: "Ladakh",
    value: "Ladakh",
  },
  {
    name: "Lakshadweep",
    value: "Lakshadweep",
  },
  {
    name: "Madhya Pradesh",
    value: "Madhya Pradesh",
  },
  {
    name: "Maharashtra",
    value: "Maharashtra",
  },
  {
    name: "Manipur",
    value: "Manipur",
  },
  {
    name: "Meghalaya",
    value: "Meghalaya",
  },
  {
    name: "Mizoram",
    value: "Mizoram",
  },
  {
    name: "Nagaland",
    value: "Nagaland",
  },
  {
    name: "Odisha",
    value: "Odisha",
  },
  {
    name: "Puducherry",
    value: "Puducherry",
  },
  {
    name: "Punjab",
    value: "Punjab",
  },
  {
    name: "Rajasthan",
    value: "Rajasthan",
  },
  {
    name: "Sikkim",
    value: "Sikkim",
  },
  {
    name: "Tamil Nadu",
    value: "Tamil Nadu",
  },
  {
    name: "Telangana",
    value: "Telangana",
  },
  {
    name: "Tripura",
    value: "Tripura",
  },
  {
    name: "Uttar Pradesh",
    value: "Uttar Pradesh",
  },
  {
    name: "Uttarakhand",
    value: "Uttarakhand",
  },
  {
    name: "West Bengal",
    value: "West Bengal",
  },
];
export const typeOfCollege = [
  {
    label: "IIIT",
    value: "1",
  },
  {
    label: "NIT",
    value: "2",
  },
  {
    label: "GFTI",
    value: "3",
  },
];

export const numOfCollegesDisplay = [
  {
    name: "20",
    value: "20",
  },
  {
    name: "30",
    value: "30",
  },
  {
    name: "40",
    value: "40",
  },
  {
    name: "50",
    value: "50",
  },
  {
    name: "100",
    value: "100",
  },
];
