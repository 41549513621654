import React, { Component, Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import "./JEEAdvForm.css";
import {MultiSelect} from "react-multi-select-component";
import { branches, categories, numOfCollegesDisplay } from "./jeeadvData";
import Footer from "../Footer/Footer";
import { fetchJeeAdv } from "../../DataServices/DataServices";
import step2 from "../../Images/step2.png";
import step3 from "../../Images/step3.png";
import jeeadvancemetaimage from "../../Images/JEE-Advanced-College-Predictor-Thumbnail.png";
import clearbtn from "../../Images/btnClear.svg";
import btnPredict from "../../Images/btnPredict.png";
import ReCAPTCHA from "react-google-recaptcha";
import mobilestep2 from "../../Images/mobilestep2.png";
import mobilestep3 from "../../Images/mobilestep3.png";
import { Helmet } from "react-helmet-async";
import { FaArrowRight } from "react-icons/fa";
import MessageModal from "./MessageModal";

const KEY = "6LfPRZkbAAAAAJYW1TQMP11aRbnTxxnQGrGa6Opq";
export default class JEEAdvForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: categories,
      branches: branches,
      numOfCollegesDisplay: numOfCollegesDisplay,
      selectedBranches: [],
      JeeAdv_catergory: "",
      JeeAdv_rank: "",
      JeeAdv_noc: "",
      gender: "",
      showResults: false,
      results: [],
      showError: false,
      errorCaught: "",
      male: false,
      female: false,
      other: false,
      verified: false,
      captchaValue: "",
      loading: false,
      clicked: false,
      verifiedcode: "1",
      requestcode: "1",
      modalIsOpen: false,
    };
  }
  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      selectedBranches: [],
      JeeAdv_catergory: "",
      JeeAdv_rank: "",
      JeeAdv_noc: "",
      gender: "",
      showResults: false,
      results: [],
      verified: false,
      male: false,
      female: false,
      other: false,
      loading: false,
    });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  captchaOnChange = async (value) => {
    var current = new Date();
    var t_ver = current.toUTCString().split(" ")[4].split(":").join("");
    var m_ver = (current.getUTCMonth() + 1).toString();
    var d_ver = current.getUTCDate().toString();
    var k_ver = "21";
    var firstcode = k_ver.concat(m_ver, d_ver, t_ver);
    this.setState({
      captchaValue: value,
      clicked: true,
      verifiedcode: firstcode,
    });
  };
  captchaonExpired = () => {
    this.setState({
      captchaValue: "",
      clicked: false,
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "gender") {
      if (e.target.id === "male") {
        this.setState({
          male: true,
          female: false,
          other: false,
        });
      }
      if (e.target.id === "female") {
        this.setState({
          male: false,
          female: true,
          other: false,
        });
      }
      if (e.target.id === "other") {
        this.setState({
          male: false,
          female: false,
          other: true,
        });
      }
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.JeeAdv_catergory === "") {
      alert("Please select category");
    } else if (this.state.selectedBranches.length === 0) {
      alert("Please select at least one branch.");
    } else if (this.state.JeeAdv_noc === "") {
      alert("Please select number of colleges to display.");
    } else {
      var formData = new FormData(e.target);
      const data = new FormData();
      data.append(
        "rank",
        formData.get("JeeAdv_rank") || this.state.JeeAdv_rank
      );
      data.append(
        "cat",
        formData.get("JeeAdv_catergory") || this.state.JeeAdv_catergory
      );
      data.append("gender", formData.get("gender") || this.state.gender);
      data.append("lmt", formData.get("JeeAdv_noc") || this.state.JeeAdv_noc);
      let branch = [];
      this.state.selectedBranches.map((b) => branch.push(b.value));
      data.append("branch", branch.join(","));

      data.append("g-recaptcha-response", this.state.captchaValue);
      data.append("verifiedcode", this.state.verifiedcode);
      data.append("requestcode", this.state.requestcode);
      if (this.state.clicked) {
        document.getElementById("JeeResults").scrollIntoView();
        this.setState({
          loading: true,
        });
        fetchJeeAdv(data)
          .then((response) => {
            if (response.error) {
              alert(response.error);
              this.setState({
                showResults: false,
                showError: true,
                loading: false,
              });
            } else {
              if (response.data.indexOf("Error") !== -1) {
                alert("Captcha timedout. Refresh the page.");
              } else {
                this.setState({
                  showResults: true,
                  showError: false,
                  results: response.data,
                  loading: false,
                });
              }
            }
          })
          .catch((error) => {
            this.setState({
              showError: true,
              error: error,
              loading: false,
            });
          });
      } else {
        alert("Captcha not verified");
      }
    }
    var date = new Date();
    var t_req = date.toUTCString().split(" ")[4].split(":").join("");
    var m_req = (date.getUTCMonth() + 1).toString();
    var d_req = date.getUTCDate().toString();
    var k_req = "21";
    var requesttime = k_req.concat(m_req, d_req, t_req);
    this.setState({
      requestcode: requesttime,
    });
    setTimeout(() => {
      this.setState({
        modalIsOpen: true,
      });
    }, 15000);
  };
  ShowResultsTable = (results) => {
    if (results) {
      if (results.length === 0) {
        return (
          <div className="row justify-content-center JeeForm_row mt-1 mb-5">
            <span className="alert alert-info">
              No Results Found For Given Data.
            </span>
          </div>
        );
      }
      return (
        <div className="row justify-content-center JeeAdvForm_row mt-1 mb-5">
          <div className="table-responsive col-12 col-md-10 JeeAdvResult_Table">
            <table className="table">
              <thead className="JeeAdvResult_tableHeader">
                <tr>
                  <td>College</td>
                  <td>Branch Name</td>
                  <td>Predicted Cuttoff Range</td>
                </tr>
              </thead>
              <tbody className="JeeAdvResult_tableBody">
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>{result.college}</td>
                    <td className="">{result.branch_name}</td>
                    <td>{result.range}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row justify-content-center JeeForm_row mt-1 mb-5">
          <span className="alert alert-info">
            Results could not be received. Please try again later.
          </span>
        </div>
      );
    }
  };
  scrollDown = () => {
    document.getElementById("VideoRow").scrollIntoView();
  };
  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="index,follow" />

          <title>JEE Advanced College Predictor 2022- Graduhat| No Login</title>
          <meta
            name="description"
            content="Free College Predictor for IIT based on your Rank, Category, Gender & preferred Branch without Login or Contact details"
          />
          <meta
            name="keywords"
            content="JEE Advanced College Predictor 2022,Graduhat,IIT,cutoff,rank,gender,category,branch,Bombay,Madras,Delhi,Kharagpur,jossa"
          />

          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="JEE Advanced College Predictor 2022- Graduhat| No Login"
          />
          <meta
            property="og:description"
            content="Free College Predictor for IIT based on your Rank, Category, Gender & preferred Branch without Login or Contact details"
          />
          <meta
            property="og:url"
            content="https://www.graduhat.com/jee-advanced-college-predictor"
          />
          <meta property="og:image" content={jeeadvancemetaimage} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@graduhat" />
          <meta name="twitter:creator" content="@graduhat" />
          <meta
            name="twitter:title"
            content="JEE Advanced College Predictor 2022- Graduhat| No Login"
          />
          <meta
            name="twitter:description"
            content="Free College Predictor for IIT based on your Rank, Category, Gender & preferred Branch without Login or Contact details"
          />
          <meta name="twitter:image" content={jeeadvancemetaimage} />
        </Helmet>
        <Navbar isForm={true} />
        <div className="row mt-3 JeeAdvForm_row justify-content-center">
          <div className="col-md-10 col-12">
            <h1 className="JeeAdvForm_headerText mb-0">
              JEE Advanced College Predictor 2022
            </h1>
            <p className="p-0 m-0 JeeAdv_subText">
              Get College Predictions and Cutoff Ranks for IITs based on your
              Rank, Category, Gender, and preferred Branch 
              <b>without Login or Contact details.</b> We have done a Predictive
              Analysis of JEE Advanced cutoff data taken from the official JOSAA
              website.
            </p>
          </div>
        </div>
        <hr />
        <div className="row mb-5 align-items-start justify-content-center JeeAdvForm_row ">
          <div className="col-12 col-md-3">
            {this.state.showResults ? (
              <picture>
                <source
                  className="stepper1"
                  media="(max-width: 576px)"
                  srcSet={mobilestep3}
                />

                <img src={step3} alt="Step3" className="stepper2" />
              </picture>
            ) : (
              <picture>
                <source
                  className="stepper1"
                  media="(max-width: 576px)"
                  srcSet={mobilestep2}
                />

                <img src={step2} alt="Step2" className="stepper2" />
              </picture>
            )}
          </div>
          <div className="col-12 mt-4 mt-md-0 col-md-7 JeeAdvForm_formCol">
            <div className="text-left">
              {" "}
              <small>
                {" "}
                We Recommend to watch our Demo Video.{" "}
                <span className="VideoLink" onClick={this.scrollDown}>
                  Click Here
                </span>
              </small>
            </div>
            <form
              id="JeeAdv_form"
              onSubmit={this.handleSubmit}
              onReset={this.handleClear}
              className="text-left"
            >
              <div className="form-row  align-items-start justify-content-between mt-3 mt-md-1">
                <div className="col-12 col-md-5">
                  <label className="JeeAdvForm_labels" htmlFor="JeeAdv_rank">
                    Rank: <span className="text-danger">*</span>
                  </label>
                  <br />

                  <input
                    onChange={this.handleChange}
                    type="number"
                    min="0"
                    name="JeeAdv_rank"
                    id="JeeAdv_rank"
                    value={this.state.JeeAdv_rank}
                    className="form-control "
                    placeholder="Enter Rank"
                    required
                  />
                  <small className="mt-0">
                    (Enter category rank if you belong to any particular
                    category)
                  </small>
                </div>
                <div className="col-12 mt-3 mt-md-0 col-md-5 ">
                  <label
                    className="JeeAdvForm_labels"
                    htmlFor="JeeAdv_category"
                  >
                    Category:
                  </label>{" "}
                  <span className="text-danger">*</span>
                  <select
                    name="JeeAdv_catergory"
                    id="JeeAdv_catergory"
                    required
                    value={this.state.JeeAdv_catergory}
                    onChange={this.handleChange}
                    className="form-control"
                  >
                    <option value="null">Select Category</option>
                    {this.state.categories.map((c, index) => (
                      <option key={index} value={c.value}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row mt-3 mt-md-4 align-items-center justify-content-between">
                <div className="col-12">
                  <label className="JeeAdvForm_labels" htmlFor="JeeAdv_branch">
                    Branch:
                  </label>{" "}
                  <span className="text-danger">*</span>
                  <MultiSelect
                    id="JeeAdv_branch"
                    options={this.state.branches}
                    value={this.state.selectedBranches}
                    onChange={(branch) =>
                      this.setState({
                        selectedBranches: branch,
                      })
                    }
                    filterOptions={(options, filter) => {
                      if (!filter) {
                        return options;
                      }
                      const re = new RegExp(filter, "i");
                      return options.filter(
                        ({ label }) => label && label.match(re)
                      );
                    }}
                    labelledBy="Branch"
                  />
                </div>
              </div>
              <div className="form-row  mt-3 mt-md-4 align-items-center justify-content-between">
                <div className="col-12 col-md-5">
                  <label className="JeeAdvForm_labels" htmlFor="JeeAdv_noc">
                    Number of Colleges to Display:
                  </label>{" "}
                  <span className="text-danger">*</span>
                  <select
                    name="JeeAdv_noc"
                    id="JeeAdv_noc"
                    required
                    value={this.state.JeeAdv_noc}
                    onChange={this.handleChange}
                    className="form-control"
                  >
                    <option value="null">
                      Select Number of Colleges to Display
                    </option>
                    {this.state.numOfCollegesDisplay.map((c, index) => (
                      <option key={index} value={c.value}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mt-3 mt-md-4">
                <b>Gender:</b> <span className="text-danger">*</span>
              </div>
              <div className="form-row align-items-center ">
                <div className="col-md-3 col-4">
                  <div className="form-row ml-1">
                    <div className="col-md-2 col-3">
                      <div className="form-check">
                        <input
                          onChange={this.handleChange}
                          className="form-check-input"
                          type="radio"
                          required
                          name="gender"
                          checked={this.state.male}
                          id="male"
                          value="Gender-Neutral"
                        />
                      </div>
                    </div>
                    <label className="JeeAdvForm_labels" htmlFor="male">
                      Male
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-4">
                  <div className="form-row">
                    <div className="col-md-2 col-3">
                      <div className="form-check">
                        <input
                          onChange={this.handleChange}
                          className="form-check-input"
                          type="radio"
                          required
                          name="gender"
                          checked={this.state.female}
                          id="female"
                          value="female"
                        />
                      </div>
                    </div>
                    <label className="JeeAdvForm_labels" htmlFor="female">
                      Female
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-4">
                  <div className="form-row">
                    <div className="col-md-2 col-3">
                      <div className="form-check">
                        <input
                          onChange={this.handleChange}
                          className="form-check-input"
                          type="radio"
                          required
                          name="gender"
                          id="other"
                          checked={this.state.other}
                          value="Gender-Neutral"
                        />
                      </div>
                    </div>
                    <label className="JeeAdvForm_labels" htmlFor="other">
                      Other
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-row justify-content-center mt-3">
                <ReCAPTCHA
                  sitekey={KEY}
                  onChange={this.captchaOnChange}
                  onExpired={this.captchaonExpired}
                />
              </div>
              <div className="form-row justify-content-center mt-3">
                <div className="col-6 col-md-4 ">
                  <button className="JeeAdvForm_btn" type="reset">
                    <div className="row m-0 JeeAdvForm_btnClear justify-content-start align-items-center">
                      <div
                        className="col-3 m-0 p-0"
                        style={{ borderRight: "2px solid #147cb8" }}
                      >
                        <img
                          src={clearbtn}
                          alt="close"
                          className="JeeAdvForm_cImg"
                        />
                      </div>
                      <div className=" col-8 input-group-append ">
                        <span className="input-group-text JeeAdvForm_btnC">
                          Clear Form
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-6 col-md-4">
                  <button className="JeeAdvForm_btn" type="submit">
                    <div className="row m-0 JeeAdvForm_btnPR justify-content-start align-items-center">
                      <div
                        className="col-3 m-0 p-0"
                        style={{ borderRight: "2px solid white" }}
                      >
                        <img
                          src={btnPredict}
                          alt="close"
                          className="JeeAdvForm_PRImg"
                        />
                      </div>
                      <div className=" col-9 m-0 p-0 input-group-append ">
                        <span className="input-group-text JeeAdvForm_btnPRtext">
                          Predict Results
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr />
        <div id="JeeResults">
          {this.state.loading ? (
            <Fragment>
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border resultSpinner mr-5"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
                <span>Loading Results...</span>
              </div>
              <hr />
            </Fragment>
          ) : null}
          {this.state.showResults ? (
            <Fragment>
              <div className="row JeeForm_row justify-content-center">
                <div className="col-10 Form_messageBorder row JeeForm_row justify-content-start align-items-center">
                  <div className="col-8 col-md-10 pl-0 pr-0 TopNugde_text">
                    <p>
                      Confused about the admission process and document
                      requirements? Don’t worry we are there for your help!
                    </p>
                  </div>
                  <div className="col-4 col-md-2 justify-content-center">
                    <a href="/admissionconsulting/">
                      <button className="KnowMore_Btn">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
              <p className="mt-2 tableText">
                Predicted Cutoff Ranks are for Final Admission Round
              </p>
              <small className="mt-2  mr-1 mobileText">
                Scroll table for more information. <FaArrowRight />
              </small>
              {this.ShowResultsTable(this.state.results)}
              <hr />
            </Fragment>
          ) : (
            <div>
              {this.state.showError ? (
                <Fragment>
                  <div className="row JeeForm_row justify-content-center">
                    <div className="col-10 Form_messageBorder row JeeForm_row">
                      <div className="col-8 col-md-10 pl-0 pr-0 TopNugde_text">
                        <p>
                          Confused about the admission process and document
                          requirements? Don’t worry we are there for your help!
                        </p>
                      </div>
                      <div className="col-4 col-md-2 justify-content-center">
                        <a href="/admissionconsulting/">
                          <button className="KnowMore_Btn">Know More</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center JeeForm_row">
                    <div
                      className="alert alert-warning mt-3 col-5"
                      role="alert"
                    >
                      Please Try Again Later.{" "}
                    </div>
                  </div>
                </Fragment>
              ) : null}
            </div>
          )}
        </div>
        <div
          className="row align-items-center justify-content-center JeeAdvForm_row "
          id="VideoRow"
        >
          <div className="col-12 col-md-5 text-left Form_textPara">
            <p className="">
              Know your chances and cutoff Ranks for prestigious colleges like
              IIT Bombay, IIT Madras, IIT Delhi, IIT Kharagpur and many more.
            </p>
            <p>
              Get Rank for all Categories (Open, SC, ST, OBC, EWS, PWD) and
              Gender.
            </p>
            <p>
              Personalize your results with your Branch Preference and College
              type.
            </p>
            <p>All the Best for your Career!</p>
            <h2
              style={{
                color: "rgb(12, 12, 98)",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              Check out our other College Predictors:
            </h2>
            <a className="Forms_links" href="/jee-main-college-predictor/">
              JEE Mains College Predictor
            </a>
            <br />
            <a className="Forms_links" href="/mht-cet-college-predictor/">
              MHT-CET College Predictor
            </a>
          </div>
          <div className="col-12 col-md-5">
            <iframe
              className="Form_video"
              src="https://www.youtube.com/embed/giijjM0cD10"
              title="YouTube video player"
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
        <hr className="mt-2 mb-2" />
        {this.state.modalIsOpen ? (
          <MessageModal closeModal={this.closeModal} modalIsOpen={true} />
        ) : null}
        <Footer className="mt-0" />
      </div>
    );
  }
}
