import React, { Component } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import logo from "../../Images/Graduhatlogo.png";

const baseurl = "/";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-light Navbar_MainDiv">
          <Link to="/" className="navbar-brand Navbar_Logo">
            {/* <a className="navbar-brand Navbar_Logo" href="/react">
             */}
            <img
              src={logo}
              alt="Graduhat Logo"
              className="logo_image img-fluid"
            />
            {/* </a> */}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse Navbar_toggleDiv"
            id="navbarToggler"
          >
            <div className="navbar-text ml-auto Navbar_RightDiv">
              <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link Navbar_TextColor"
                    aria-current="page"
                    href={
                      this.props.isForm
                        ? baseurl + "#collegepredictor"
                        : "#collegepredictor"
                    }
                  >
                    College Predictor
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link Navbar_TextColor"
                    href={this.props.isForm ? baseurl + "#news" : "#news"}
                  >
                    News
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link Navbar_TextColor"
                    href={
                      this.props.isForm
                        ? baseurl + "#admissionconsulting"
                        : "#admissionconsulting"
                    }
                  >
                    Admission Consulting
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link Navbar_TextColor"
                    href={
                      this.props.isForm ? baseurl + "#contactus" : "#contactus"
                    }
                  >
                    Contact Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link Navbar_TextColor" href="/login">
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
