import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import logo from "../../Images/Graduhatlogo.png";
import { getAmount } from "../commonUtils/commonUtils";
import "./SuccessPage.css";
class SuccessPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentId: props?.location?.state?.paymentId || "",
      planNumber: props?.location?.state?.planNumber || "",
    };
  }

  render() {
    // if (this.state.paymentId.trim() === "") {
    //   this.props.history.replace("/admissionconsulting");
    // }
    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index,follow" />
          <title>Payment Status</title>
        </Helmet>
        <div className="row SuccessPage_row mt-2 align-items-center justify-content-center">
          <div className="col-12">
            <div className="row SuccessPage_row mt-5 align-items-center justify-content-center">
              <div className="col-11 col-md-8 SuccessPage_header">
                <div className="row SuccessPage_row  align-items-center justify-content-between">
                  <div className="pl-0 pr-0 pl-md-4 col-3">
                    <img
                      src={logo}
                      alt="Graduhat Logo"
                      className="logo_image_plan img-fluid"
                    />
                  </div>
                  <div className="col-6 col-md-2 SuccessPage_headerText">
                    Plan {this.state.planNumber} |{" "}
                    {getAmount(this.state.planNumber)}
                  </div>
                </div>
              </div>
              <div className=" col-11 col-md-8 SuccessPage_subDiv">
                <p className="SuccessPage_text">Payment Successful</p>
                <p>Payment Id:{this.state.paymentId}</p>
                <div className="text-left">
                  <p>
                    A confirmation email has been sent to your Registered Email
                    Id, please check all folders including the spam folder.
                  </p>
                  <p>
                    To access the chat feature login to your dashboard via
                    homepage or by clicking the link below{" "}
                    <a href="/login" className="SuccessPage_links">
                      Login
                    </a>
                  </p>
                  <p>
                    To schedule a video call, message us in the chat window or
                    email us at{" "}
                    <a
                      className="SuccessPage_links"
                      href="mailto:service@graduhat.com"
                    >
                      service@graduhat.com
                    </a>
                  </p>
                  <p>
                    Click here to return to{" "}
                    <a href="/" className="SuccessPage_links">
                      Homepage
                    </a>
                  </p>
                  <p>
                    For any issue connect us on social media or email us at{" "}
                    <a
                      className="SuccessPage_links"
                      href="mailto:support@graduhat.com"
                    >
                      support@graduhat.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default SuccessPage;
