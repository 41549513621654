import React, { Component, Fragment } from "react";
import "./AboutUs.css";
import person1 from "../../Images/AboutUs1.jpg";
import person2 from "../../Images/AboutUs2.jpg";
import { FaLinkedin } from "react-icons/fa";

export default class AboutUs extends Component {
  render() {
    return (
      <Fragment>
        <div className="row justify-content-center AboutUs_row">
          <div className="col-12 col-md-10  text-center">
            <h2 className="AboutUs_headerText">About Graduhat</h2>
            <p className="text-center AboutUs_subheaderText">
              Graduhat was created to assist students in their Engineering
              Admissions and Option/Choice Form filling in best possible way.
              <br />
              We value importance of your precious time; hence we created all
              the features <b>without login or contact details</b> to save your
              time.
            </p>
            <h5 style={{ color: "rgb(12, 12, 98)" }}>
              What the founders have to say:
            </h5>
            <a
              href="https://www.linkedin.com/in/sourav-sankpal-b3b01b191/"
              className="AboutUs_links"
            >
              <img
                src={person1}
                alt="Name of person"
                className="AboutUs_personImg img-fluid"
              />
              <div style={{ color: "black", marginBottom: "0" }}>
                Sourav Sankpal
                <br />
                B.Tech, VJTI Mumbai
              </div>
              <span>
                <FaLinkedin size="1rem" />
                <small style={{ color: "grey" }}> Sourav Sankpal</small>
              </span>
            </a>
            <div className="mt-3 mb-5">
              <i>
                <p className="AboutUs_textMargins">
                  “Back when I was in the phase of taking admission for
                  Engineering, I felt there were no or very few options for
                  counseling or consulting on regards to which colleges to apply
                  to or which branch to choose. This thought always meandered my
                  mind and eventually resulted in the foundation of the website
                  which you are on right now!
                  <br />
                  <br />
                  I’d say this is a website for the students by the students”.
                </p>
              </i>
            </div>

            <img
              src={person2}
              alt="Name of person"
              className="AboutUs_personImg img-fluid"
            />
            <div style={{ color: "black", marginBottom: "0" }}>
              Sonali Sankpal
              <br />
              PhD, Electronics Engineering
            </div>
            <span>
              <small style={{ color: "grey" }}> Sonali Sankpal</small>
            </span>

            <div className="mt-3">
              <i>
                <p className="AboutUs_textMargins">
                  “In my 20 years of experience as an educator, I found a
                  missing platform, which can understand student's needs and
                  help parents make a conscious choice of colleges. Many
                  students met me personally for counseling, but my reach was
                  limited. We decided to reach every possible student through
                  this website.
                  <br />
                  <br />
                  I’d say this website is like a lighthouse to serve as a beacon
                  for students in the turbulent Admission procedure”.
                </p>
              </i>
            </div>
          </div>
        </div>
        <hr />
      </Fragment>
    );
  }
}
