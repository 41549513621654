import React, { Component, Fragment } from "react";
import "./ContactUs.css";

import {
  FaEnvelope,
  FaYoutube,
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa";
export default class ContactUs extends Component {
  render() {
    return (
      <Fragment>
        <div className="row justify-content-center ContactUs_row ContactUs_Margins">
          <div className="col-12 col-md-10">
            <h2 className="ContactUs_headerText">Contact Us</h2>
            <p className="text-center ContactUs_subheaderText">
              We are here to serve you in the best possible way. Do let us know
              your feedback or your query. We are happy to help you always.
            </p>
            <p className="text-center ContactUs_subheaderText">
              North Shivaji Nagar, Sangli, Maharashtra
            </p>
          </div>
        </div>
        <div className="row mt-3 justify-content-center ContactUs_row">
          <div className="col-md-10 col-12">
            <div className="row">
              <div className="col-md-3 col-4">
                <a
                  href="https://www.linkedin.com/company/graduhat"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin className="ContactUs_icons" color="#0077b5" />
                  <p className="mb-0 AboutUs_linksText">
                    <small style={{ color: "black" }}>@Graduhat</small>
                  </p>
                </a>
              </div>
              <div className="col-md-3 col-4">
                <a
                  href="https://www.facebook.com/GraduhatIndia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook className="ContactUs_icons" color="#4968ad" />
                  <p className="mb-0 AboutUs_linksText">
                    <small style={{ color: "black" }}>@graduhatindia</small>
                  </p>
                </a>
              </div>
              <div className="col-md-3 col-4">
                <a
                  href="https://twitter.com/graduhat"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter className="ContactUs_icons" color="#49a1eb" />
                  <p className="mb-0 AboutUs_linksText">
                    <small style={{ color: "black" }}>@graduhat</small>
                  </p>
                </a>
              </div>
              <div className="col-md-3 col-4 MobileViewHide">
                <a
                  href="https://www.instagram.com/graduhatindia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="ContactUs_icons" color="#E1306C" />
                  <p className="mb-0 AboutUs_linksText">
                    <small style={{ color: "black" }}>@graduhatindia</small>
                  </p>
                </a>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-3 col-4 MobileViewShow">
                <a
                  href="https://www.instagram.com/graduhatindia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="ContactUs_icons" color="#E1306C" />
                  <p className="mb-0 AboutUs_linksText">
                    <small style={{ color: "black" }}>@graduhatindia</small>
                  </p>
                </a>
              </div>
              <div className="col-md-6 col-4 pl-0 pl-md-1">
                <a href="mailto:sourav@graduhat.com">
                  <FaEnvelope color="#EA4335" className="ContactUs_icons" />
                  <p className="mb-0 AboutUs_linksText">
                    <small style={{ color: "black" }}>
                      sourav@graduhat.com
                    </small>
                  </p>
                </a>
              </div>
              <div className="col-md-6 col-4">
                <a
                  href="https://www.youtube.com/channel/UCWQ-rfoMT4-OSGXPaQ4tlJg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube className="ContactUs_icons" color="#eb3223" />

                  <p className="mb-0 AboutUs_linksText">
                    <small style={{ color: "black" }}>@Graduhat</small>
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </Fragment>
    );
  }
}
