import React, { useEffect, useRef } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export default function Tawk(props) {
  const tawkMessengerRef = useRef();
  const onLoad = () => {
    if (tawkMessengerRef?.current?.setAttributes !== undefined) {
      tawkMessengerRef?.current?.setAttributes(
        {
          name: props.fullName,
          email: props.email,
          hash: props.hashCode,
        },
        function (error) {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    // if (tawkMessengerRef?.current) tawkMessengerRef?.current?.hideWidget();
    return () => {
      if (tawkMessengerRef?.current) tawkMessengerRef?.current?.showWidget();
    };
  }, []);

  return (
    <div className="App">
      <TawkMessengerReact
        propertyId="6225b00ea34c24564129c576"
        widgetId="1fthjfen1"
        ref={tawkMessengerRef}
        onLoad={onLoad}
      />
    </div>
  );
}
