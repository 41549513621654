import React, { Component, Fragment } from "react";
import "./CollegePredictor.css";
import jee from "../../Images/jee.png";
import jeeadv from "../../Images/jeeadv.png";
import cet from "../../Images/mhcet.jpg";
import step1 from "../../Images/step1.png";
// import { Link } from "react-router-dom";
import mobilestep1 from "../../Images/mobilestep1.png";
export default class CollegePredictor extends Component {
  render() {
    return (
      <Fragment>
        <div className="row CollegePredictor_row CollegePredictor_marginTop justify-content-center">
          <div className="col-md-10 col-12 text-center CollegePredictor_Text">
            <h2 className="CollegePredictor_headerText">College Predictor</h2>
            <p className="CollegePredictor_subheaderText">
              Get the predicted Cutoff ranks for IITs, NITs, and all other
              private and government institutes with our advanced algorithm for
              2022. Get your results according to your Rank, Category, Gender,
              Home state, and preferred Branch and College type 
              <b>without Login or Contact details.</b>
            </p>
          </div>
        </div>
        <div className="row CollegePredictor_row justify-content-center">
          <div className="col-11">
            <div className="row CollegePredictor_row justify-content-center">
              <div className="col-12 col-md-3 CollegePredictor_colLeft">
                <picture>
                  <source
                    className="stepper1"
                    media="(max-width: 576px)"
                    srcSet={mobilestep1}
                  />

                  <img src={step1} alt="Step1" className="stepper1" />
                </picture>
                {/*  <div class="info-timeline">
                  <ul>
                    <li>
                      <span class="timeline-circle step_active">1</span>
                      example 1
                    </li>
                    <li>
                      <span class="timeline-circle">2</span>
                      example 2
                    </li>
                    <li>
                      <span class="timeline-circle">3</span>
                      example 3
                    </li>
                  </ul>
                </div>
              */}
              </div>
              <div className="col-12 mt-2 mt-md-0 col-md-7 text-center CollegePredictor_colRight">
                <p style={{ fontWeight: "bold" }}>Select Exam You Have Taken</p>
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-4 CollegePredictor_examLogoDiv">
                    <a
                      href="/jee-main-college-predictor/"
                      className="CollegePredictor_links"
                    >
                      {/*  
                    <Link to="/jee">
                     */}
                      <img
                        src={jee}
                        alt="JEE"
                        className="CollegePredictor_examImg"
                      />
                      <p
                        className="mt-2 CollegePredictor_examText"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        JEE Mains
                      </p>
                    </a>
                    {/* 
                      </Link>
                    */}
                  </div>
                  <div className="col-4 CollegePredictor_examLogoDiv">
                    {" "}
                    <a
                      href="/jee-advanced-college-predictor/"
                      className="CollegePredictor_links"
                    >
                      {/*  
                      <Link to="jeeadv">
                    */}
                      <img
                        src={jeeadv}
                        alt="JEE Advance"
                        className="CollegePredictor_examImg"
                      />
                      <p
                        className="mt-2 CollegePredictor_examText"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        JEE Advanced
                      </p>
                    </a>
                    {/*  
                      </Link>
                    */}
                  </div>
                  <div className="col-4 CollegePredictor_examLogoDiv">
                    {" "}
                    {/* 
                      <Link to="cet">
                    */}
                    <a
                      href="/mht-cet-college-predictor/"
                      className="CollegePredictor_links"
                    >
                      <img
                        src={cet}
                        alt="MHT-CET"
                        className="CollegePredictor_examImg"
                      />
                      <p
                        className="mt-2 CollegePredictor_examText"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        MHT-CET
                      </p>
                    </a>
                    {/*
                        </Link>
                     */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </Fragment>
    );
  }
}
