import React, { Component, Fragment } from "react";
import "./Plan.css";
import logo from "../../Images/Graduhatlogo.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet-async";
import {
  createOrder,
  verifyPlan,
  verifyEmail,
} from "../../DataServices/DataServices";
import { getAmount } from "../commonUtils/commonUtils";
import { withRouter } from "react-router-dom";
import graphic1 from "../../Images/graphic1.png";
import ModalPrivacyPolicy from "../Footer/ModalPrivacyPolicy";
import ModalTerms from "../Footer/ModalTerms";
import homepageimage from "../../Images/Home-Page-Thumbnail.png";

class PlanPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planNumber: props?.location?.state || "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phoneNumber: "",
      dob: new Date(),
      city: "",
      male: false,
      female: false,
      examType: "",
      loading: false,
      razorpayOrderId: "",
      paymentSuccess: true,
      examTypeArray: [
        {
          value: "JEE Main & JEE Advanced",
          name: "JEE Main & JEE Advanced",
        },
        {
          value: "MHT-CET",
          name: "MHT-CET",
        },
      ],
      modalIsOpenPP: false,
      modalIsOpenT: false,
      consent: false,
    };
  }
  closeModal = () => {
    this.setState({ modalIsOpenPP: false });
  };
  openModal = () => {
    this.setState({ modalIsOpenPP: true });
  };
  closeModalTerms = () => {
    this.setState({ modalIsOpenT: false });
  };
  openModalTerms = () => {
    this.setState({ modalIsOpenT: true });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  handleDateChange = (date) => {
    this.setState({
      dob: date,
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "gender") {
      if (e.target.id === "male") {
        this.setState({
          male: true,
          female: false,
        });
      }
      if (e.target.id === "female") {
        this.setState({
          male: false,
          female: true,
        });
      }
    }
  };
  handleChangeCheckBox = () => {
    this.setState({
      consent: !this.state.consent,
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.consent) {
      if (this.state.examType === undefined || this.state.examType === "") {
        alert("Please select exam.");
      } else {
        if (this.state.phoneNumber.length !== 10) {
        } else {
          var formData = new FormData(e.target);
          const data = new FormData();
          data.append(
            "fname",
            formData.get("firstName") || this.state.firstName
          );
          data.append("lname", formData.get("lastName") || this.state.lastName);
          data.append("email", formData.get("email") || this.state.email);
          data.append(
            "contact",
            formData.get("phoneNumber") || this.state.phoneNumber
          );
          data.append("pword", formData.get("password") || this.state.password);
          if (this.state.male) {
            data.append("gender", "male");
          } else {
            data.append("gender", "female");
          }
          data.append("dob", this.state.dob.toLocaleDateString());
          data.append("city", formData.get("city") || this.state.city);
          data.append("exam", formData.get("examType") || this.state.examType);

          const amount = getAmount(this.state.planNumber).toString();
          data.append("payment", amount);
          data.append("consent", this.state.consent.toString());

          this.setState({
            loading: true,
          });
          const order = new FormData();
          order.append("plan", this.state.planNumber);
          order.append("amount", amount);

          const emailVerify = new FormData();
          emailVerify.append(
            "email",
            formData.get("email") || this.state.email
          );
          await verifyEmail(emailVerify)
            .then((response) => {
              if (
                response?.data &&
                (response?.data !== undefined || response?.data !== null) &&
                !response?.error
              ) {
                createOrder(order)
                  .then((response) => {
                    if (response.error) {
                      alert(response.error);
                    } else {
                      if (
                        !!response.data &&
                        response.data.razorpayOrderId !== ""
                      ) {
                        this.setState({
                          razorpayOrderId: response.data.razorpayOrderId,
                        });
                        this.paymentProcess(data);
                      } else {
                        alert("Internal Error. Please try again later.");
                      }
                    }
                  })
                  .catch((error) => {
                    alert("Internal Error. Please try again later.");
                  });
              } else {
                alert("Email already exists. Please try again.");
              }
            })
            .catch((error) => {
              alert("Internal Error. Please try again later.");
            });
        }
      }
    } else {
      alert("Please agree to the Privacy Policy and Terms and Conditions.");
    }
  };
  paymentProcess = async (formData) => {
    const { history } = this.props;
    const planNum = this.state.planNumber;
    const res = await this.loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (this.state.razorpayOrderId === "") {
      alert("Server error. Are you online?");
      return;
    }
    const amount = getAmount(this.state.planNumber).toString();

    const options = {
      key: "rzp_live_QYfKln5MzzA7Sy", // Enter the Key ID generated from the Dashboard
      amount: getAmount(this.state.planNumber),
      name: "Graduhat",
      description: `Plan ${this.state.planNumber} | ₹${amount}`,
      image: homepageimage,
      order_id: this.state.razorpayOrderId,
      handler: async function (response) {
        const orderData = new FormData();
        for (var pair of formData.entries()) {
          orderData.append(pair[0], pair[1]);
        }
        orderData.append("razorpay_payment_id", response.razorpay_payment_id);
        orderData.append("razorpay_order_id", response.razorpay_order_id);
        orderData.append("razorpay_signature", response.razorpay_signature);

        await verifyPlan(orderData)
          .then((response) => {
            if (response.error) {
              alert(response.error);
            } else {
              if (response.data.success) {
                history.replace("/orderstatus", {
                  planNumber: planNum,
                  paymentId: response.data.payment_id,
                });
              } else {
                alert(
                  "Payment failed. Please try again later or contact us at support@graduhat.com"
                );
                window.location.reload();
              }
            }
          })
          .catch((error) => {
            alert("Internal Error. Please try again later.", error);
          });
      },
      prefill: {
        name: `${this.state.firstName} ${this.state.lastName}`,
        email: `${this.state.email}`,
        contact: `${this.state.phoneNumber}`,
      },
      notes: {
        address: "Hello World",
        merchant_order_id: "12312321",
      },
      theme: {
        color: "#3f48cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  render() {
    if (this.state.planNumber.trim() === "") {
      this.props.history.replace("/admissionconsulting");
    }
    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index,follow" />
          <title>Plan</title>
        </Helmet>
        <div
          style={{
            backgroundImage: `url(${graphic1})`,
          }}
          className="row PlanPage_BackGround PlanPage_row mt-2 align-items-center justify-content-center"
        >
          <div className="col-12">
            <div className="row PlanPage_row mt-5 align-items-center justify-content-center">
              <div className="col-11 col-md-10 PlanPage_header">
                <div className="row PlanPage_row  align-items-center justify-content-between">
                  <div className="pl-0 pr-0 pl-md-4 col-3">
                    <a href="/">
                      <img
                        src={logo}
                        alt="Graduhat Logo"
                        className="logo_image_plan img-fluid"
                      />
                    </a>
                  </div>
                  <div className="col-6 col-md-2 PLanPage_headerText">
                    Plan {this.state.planNumber} | ₹
                    {getAmount(this.state.planNumber)}
                  </div>
                </div>
              </div>
              <div className="col-11 col-md-10 PlanPage_formDiv">
                <form
                  id="plan_form"
                  onReset={this.handleClear}
                  onSubmit={this.handleSubmit}
                  className="text-left"
                >
                  <div className="form-row  mt-3 mt-md-1 align-items-start justify-content-between">
                    <div className="col-12 col-md-5">
                      <label className="PlanPage_label" htmlFor="firstName">
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="text"
                        name="firstName"
                        id="firstName"
                        value={this.state.firstName}
                        className="form-control"
                        placeholder="First Name*"
                        required
                      />
                    </div>
                    <div className="col-12 mt-3 mt-md-0 col-md-5  ">
                      <label className="PlanPage_label" htmlFor="lastName">
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="text"
                        name="lastName"
                        id="lastName"
                        value={this.state.lastName}
                        className="form-control"
                        placeholder="Last Name*"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row  mt-3 mt-md-3 align-items-start justify-content-between">
                    <div className="col-12 col-md-10 ">
                      <label className="PlanPage_label" htmlFor="email">
                        Confirmation receipt and further communication
                        notifications would be sent to this email.
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="email"
                        name="email"
                        id="email"
                        value={this.state.email}
                        className="form-control"
                        placeholder="Your Email*"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row  mt-3 mt-md-3 align-items-center justify-content-between">
                    <label className="PlanPage_label" htmlFor="email">
                      An account would be created after the payment. Set a
                      password to access the account.
                    </label>
                    <div className="col-12 col-md-5 ">
                      <input
                        onChange={this.handleChange}
                        type="password"
                        name="password"
                        id="password"
                        value={this.state.password}
                        minLength={8}
                        className="form-control"
                        placeholder="Set Password*"
                        required
                      />
                    </div>
                    <div className="col-12 PlanPage_passwordText mt-3 mt-md-0 col-md-7">
                      Minimum 8 characters
                      <span className="text-danger">*</span>
                    </div>
                  </div>
                  <div className="form-row  mt-3 mt-md-4 align-items-start justify-content-between">
                    <div className="col-12 col-md-5 ">
                      <label className="PlanPage_label" htmlFor="phoneNumber">
                        Phone Number<span className="text-danger">*</span>
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="tel"
                        pattern="[0-9]{10}"
                        name="phoneNumber"
                        maxLength={10}
                        id="phoneNumber"
                        value={this.state.phoneNumber}
                        className="form-control"
                        placeholder="Phone Number*"
                        required
                      />
                    </div>
                    <div className="col-12 mt-3 mt-md-0 col-md-5">
                      <label className="PlanPage_label" htmlFor="birthDate">
                        Birth Date<span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        name="dob"
                        id="dob"
                        className="form-control"
                        dateFormat="yyyy/MM/dd"
                        selected={this.state.dob}
                        onChange={this.handleDateChange}
                        maxDate={new Date()}
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        onClickOutside={this.state.dob}
                      />
                    </div>
                  </div>
                  <div className="form-row  mt-3 mt-md-4 align-items-start justify-content-between">
                    <div className="col-12 col-md-5 ">
                      <label className="PlanPage_label" htmlFor="city">
                        City<span className="text-danger">*</span>
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="text"
                        name="city"
                        id="city"
                        value={this.state.city}
                        className="form-control"
                        placeholder="City*"
                        required
                      />
                    </div>
                    <div className="col-12 mt-3 mt-md-0 col-md-5">
                      <label className="PlanPage_label" htmlFor="birthDate">
                        Gender<span className="text-danger">*</span>
                      </label>
                      <div className="form-row align-items-center">
                        <div className="col-md-5 col-4">
                          <div className="form-row ml-1">
                            <div className="col-md-3 col-3">
                              <div className="form-check">
                                <input
                                  onChange={this.handleChange}
                                  className="form-check-input"
                                  type="radio"
                                  required
                                  name="gender"
                                  id="male"
                                  checked={this.state.male}
                                  value="male"
                                />
                              </div>
                            </div>
                            <label className="PlanPage_label" htmlFor="male">
                              Male
                            </label>
                          </div>
                        </div>
                        <div className="col-md-5 col-4">
                          <div className="form-row ml-1">
                            <div className="col-md-3 col-3">
                              <div className="form-check">
                                <input
                                  onChange={this.handleChange}
                                  className="form-check-input"
                                  type="radio"
                                  required
                                  name="gender"
                                  id="female"
                                  checked={this.state.female}
                                  value="female"
                                />
                              </div>
                            </div>
                            <label className="PlanPage_label" htmlFor="male">
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row  mt-3 mt-md-4 align-items-start justify-content-between">
                    <div className="col-md-7 col-12">
                      <label className="PlanPage_label" htmlFor="Jee_category">
                        Select the exam for which you require consulting
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="examType"
                        id="examType"
                        required
                        value={this.state.examType}
                        onChange={this.handleChange}
                        className="form-control"
                      >
                        <option value="null">Select Exam</option>
                        {this.state.examTypeArray.map((c, index) => (
                          <option key={index} value={c.value}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-row  mt-3 mt-md-4 align-items-start justify-content-between">
                    <div className="col-1 ">
                      <input
                        type="checkbox"
                        name="consent"
                        id="consent"
                        onChange={this.handleChangeCheckBox}
                        value={this.state.consent}
                      />
                    </div>
                    <div className="col-11 ">
                      <label
                        className="Plan_termText Plan_termText ml-2"
                        htmlFor="consent"
                      >
                        I agree to Graduhat’s
                        <button
                          onClick={this.openModal}
                          className="Plan_Pbtn"
                          type="button"
                        >
                          Privacy Policy
                        </button>
                        and
                        <button
                          onClick={this.openModalTerms}
                          className="Plan_Pbtn"
                          type="button"
                        >
                          Terms and Conditions
                        </button>
                      </label>
                    </div>
                  </div>
                  <div className="form-row mt-5 justify-content-center">
                    <div className="col-md-3 col-6 text-center">
                      <button
                        className="PlanPage_gobackBtn"
                        onClick={() => this.props.history.goBack()}
                      >
                        Go Back
                      </button>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                      <button className="PlanPage_payBtn" type="submit">
                        Pay Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {this.state.modalIsOpenPP && (
          <ModalPrivacyPolicy
            closeModal={this.closeModal}
            modalIsOpenPP={true}
          />
        )}
        {this.state.modalIsOpenT && (
          <ModalTerms closeModal={this.closeModalTerms} modalIsOpenT={true} />
        )}
      </Fragment>
    );
  }
}

export default withRouter(PlanPage);
