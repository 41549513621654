import React, { Component } from "react";
import { fetchLatestNews } from "../../DataServices/DataServices";
import "./MarqueePage.css";
export default class MarqueePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latestNews: [],
    };
  }
  async loadData() {
    await fetchLatestNews()
      .then((response) => {
        if (response) {
          if (response.error) {
          } else {
            if (
              response.data.indexOf("Error") === -1 &&
              response.data.indexOf("<html>") === -1
            )
              this.setState({ latestNews: response.data });
          }
        } else {
        }
      })
      .catch((error) => {
        this.setState({
          showError: true,
          error: error,
        });
      });
  }
  componentDidMount() {
    this.loadData();
  }

  renderNewLinks = () => {
    return;
  };
  render() {
    if (this.state.latestNews.length === 0) {
      return <div></div>;
    } else {
      return (
        <div className="row justify-content-center align-items-center Marquee_Row text-start">
          <div className="col-2 col-md-1 Marquee_Icon mr-0">Latest News</div>
          <div className="col-9 Marquee_ColMargins pl-0 ml-0">
            <marquee behaviour="scroll">
              {this.state.latestNews.map((news, index) => (
                <span key={index}>
                  <a
                    key={index}
                    href={news.LINK}
                    target="_blank"
                    className="Marquee_newsLinks"
                    rel="noreferrer"
                  >
                    {news.NEWS}
                  </a>
                </span>
              ))}
            </marquee>
          </div>
        </div>
      );
    }
  }
}
