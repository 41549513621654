/*
for single select
{
  name:"",
  value:""
}

for multi select
{
  label:"",
  value:""
}
*/
export const categories = [
  {
    name: "OPEN",
    value: "OPEN",
  },
  {
    name: "OBC-NCL",
    value: "OBC-NCL",
  },
  {
    name: "EWS",
    value: "EWS",
  },
  {
    name: "SC",
    value: "SC",
  },
  {
    name: "ST",
    value: "ST",
  },
  {
    name: "OPEN (PwD)",
    value: "OPEN (PwD)",
  },
  {
    name: "OBC-NCL (PwD)",
    value: "OBC-NCL (PwD)",
  },
  {
    name: "EWS (PwD)",
    value: "EWS (PwD)",
  },
  {
    name: "SC (PwD)",
    value: "SC (PwD)",
  },
  {
    name: "ST (PwD)",
    value: "ST (PwD)",
  },
];

export const branches = [
  {
    label: "Civil Engg. (4 Years, B.Tech)",
    value: 1,
  },
  {
    label:
      "Civil Engg. and M. Tech. in Structural Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 2,
  },
  {
    label:
      "Civil Engg. and M.Tech in Transportation Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 3,
  },
  {
    label:
      "Civil Engg. and M.Tech. in Environmental Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 4,
  },
  {
    label: "Computer Science and Engg. (4 Years, B.Tech)",
    value: 5,
  },
  {
    label:
      "Computer Science and Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 6,
  },
  {
    label:
      "Electrical Engg. and M.Tech Power Electronics and Drives (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 7,
  },
  {
    label: "Electrical Engg. (4 Years, B.Tech)",
    value: 8,
  },
  {
    label: "Electronics and Communication Engg. (4 Years, B.Tech)",
    value: 9,
  },
  {
    label: "Mechanical Engg. (4 Years, B.Tech)",
    value: 10,
  },
  {
    label:
      "Mechanical Engg. and M. Tech. in Mechanical System Design (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 11,
  },
  {
    label:
      "Mechanical Engg. and M. Tech. in Thermal Science & Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 12,
  },
  {
    label:
      "Mechanical Engg. with M.Tech. in Manufacturing Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 13,
  },
  {
    label: "Metallurgical and Materials Engg. (4 Years, B.Tech)",
    value: 14,
  },
  {
    label:
      "Metallurgical and Materials Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 15,
  },
  {
    label: "Aerospace Engg. (4 Years, B.Tech)",
    value: 16,
  },
  {
    label: "BS in Mathematics (4 Years, Bachelor of Science)",
    value: 17,
  },
  {
    label: "Chemical Engg. (4 Years, B.Tech)",
    value: 18,
  },
  {
    label: "Chemistry (4 Years, Bachelor of Science)",
    value: 19,
  },
  {
    label: "Economics (4 Years, Bachelor of Science)",
    value: 20,
  },
  {
    label: "Electrical Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 21,
  },
  {
    label:
      "Energy Engg. with M.Tech. in Energy Systems Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 22,
  },
  {
    label: "Engg. Physics (4 Years, B.Tech)",
    value: 23,
  },
  {
    label:
      "Environmental Science and Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 24,
  },
  {
    label:
      "Mechanical Engg. and M.Tech. in Computer Integrated Manufacturing (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 25,
  },
  {
    label:
      "Metallurgical Engg. & Materials Science (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 26,
  },
  {
    label: "Metallurgical Engg. and Materials Science (4 Years, B.Tech)",
    value: 27,
  },
  {
    label: "Bio Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 28,
  },
  {
    label: "Data Science and Engg. (4 Years, B.Tech)",
    value: 29,
  },
  {
    label: "Biotechnology and Biochemical Engg. (4 Years, B.Tech)",
    value: 30,
  },
  {
    label: "Chemical Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 31,
  },
  {
    label: "Electrical Engg. (Power and Automation) (4 Years, B.Tech)",
    value: 32,
  },
  {
    label: "Energy Engg. (4 Years, B.Tech)",
    value: 33,
  },
  {
    label: "Engg. and Computational Mechanics (4 Years, B.Tech)",
    value: 34,
  },
  {
    label: "Materials Engg. (4 Years, B.Tech)",
    value: 35,
  },
  {
    label: "Mathematics and Computing (4 Years, B.Tech)",
    value: 36,
  },
  {
    label:
      "Mathematics and Computing (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 37,
  },
  {
    label: "Production and Industrial Engg. (4 Years, B.Tech)",
    value: 38,
  },
  {
    label: "Textile Technology (4 Years, B.Tech)",
    value: 39,
  },
  {
    label: "Aerospace Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 40,
  },
  {
    label: "Agricultural and Food Engg. (4 Years, B.Tech)",
    value: 41,
  },
  {
    label:
      "Agricultural and Food Engg. with M.Tech. in any of the listed specializations (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 42,
  },
  {
    label: "Applied Geology (4 Years, Bachelor of Science)",
    value: 43,
  },
  {
    label: "Architecture (5 Years, B.Arch)",
    value: 44,
  },
  {
    label:
      "Biotechnology and Biochemical Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 45,
  },
  {
    label:
      "Civil Engg. with any of the listed specialization (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 46,
  },
  {
    label:
      "Electrical Engg. with M.Tech. in any of the listed specializations (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 47,
  },
  {
    label: "Electronics and Electrical Communication Engg. (4 Years, B.Tech)",
    value: 48,
  },
  {
    label:
      "Electronics and Electrical Communication Engg. with M.Tech. in any of the listed specializations (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 49,
  },
  {
    label: "Exploration Geophysics (4 Years, Bachelor of Science)",
    value: 50,
  },
  {
    label: "Industrial and Systems Engg. (4 Years, B.Tech)",
    value: 51,
  },
  {
    label:
      "Industrial and Systems Engg. with M.Tech. in Industrial and Systems Engg. and Management (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 52,
  },
  {
    label: "Instrumentation Engg. (4 Years, B.Tech)",
    value: 53,
  },
  {
    label: "Manufacturing Science and Engg. (4 Years, B.Tech)",
    value: 54,
  },
  {
    label:
      "Manufacturing Science and Engg. with M.Tech. in Industrial and Systems Engg. and Management (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 55,
  },
  {
    label: "Mathematics and Computing (4 Years, Bachelor of Science)",
    value: 56,
  },
  {
    label:
      "Mechanical Engg. with M.Tech. in any of the listed specializations (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 57,
  },
  {
    label: "Mining Engg. (4 Years, B.Tech)",
    value: 58,
  },
  {
    label: "Mining Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 59,
  },
  {
    label: "Mining Safety Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 60,
  },
  {
    label: "Ocean Engg. and Naval Architecture (4 Years, B.Tech)",
    value: 61,
  },
  {
    label:
      "Ocean Engg. and Naval Architecture (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 62,
  },
  {
    label: "Physics (4 Years, Bachelor of Science)",
    value: 63,
  },
  {
    label: "Artificial Intelligence (4 Years, B.Tech)",
    value: 64,
  },
  {
    label: "Biomedical Engg. (4 Years, B.Tech)",
    value: 65,
  },
  {
    label: "Biotechnology and Bioinformatics (4 Years, B.Tech)",
    value: 66,
  },
  {
    label: "Computational Engg. (4 Years, B.Tech)",
    value: 67,
  },
  {
    label: "Engg. Science (4 Years, B.Tech)",
    value: 68,
  },
  {
    label: "Industrial Chemistry (4 Years, B.Tech)",
    value: 69,
  },
  {
    label: "Materials Science and Metallurgical Engg. (4 Years, B.Tech)",
    value: 70,
  },
  {
    label: "Artificial Intelligence and Data Science (4 Years, B.Tech)",
    value: 71,
  },
  {
    label: "Bio Engg. (4 Years, B.Tech)",
    value: 72,
  },
  {
    label: "Civil and Infrastructure Engg. (4 Years, B.Tech)",
    value: 73,
  },
  {
    label: "Biological Sciences and Bioengineering (4 Years, B.Tech)/ Biosciences and Bioengineering (4 Years, B.Tech)",
    value: "74,85",
  },
  {
    label: "Earth Sciences (4 Years, Bachelor of Science)",
    value: 75,
  },
  {
    label: "Materials Science and Engg. (4 Years, B.Tech)",
    value: 76,
  },
  {
    label:
      "Mathematics and Scientiï¬c Computing (4 Years, Bachelor of Science)",
    value: 77,
  },
  {
    label: "Statistics and Data Science (4 Years, Bachelor of Science)",
    value: 78,
  },
  {
    label: "Biological Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 79,
  },
  {
    label:
      "Biological Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    value: 80,
  },
  {
    label: "Engg. Design (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 81,
  },
  {
    label: "Naval Architecture and Ocean Engg. (4 Years, B.Tech)",
    value: 82,
  },
  {
    label:
      "Physics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    value: 83,
  },
  {
    label: "Electrical and Electronics Engg. (4 Years, B.Tech)",
    value: 84,
  },
  {
    label: "Biosciences and Bioengineering (4 Years, B.Tech)",
    value: 85,
  },
  {
    label:
      "Chemical Sciences (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    value: 86,
  },
  {
    label:
      "Economics (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    value: 87,
  },
  {
    label: "Geological Technology (5 Years, Integrated M.Tech)",
    value: 88,
  },
  {
    label: "Geophysical Technology (5 Years, Integrated M.Tech)",
    value: 89,
  },
  {
    label:
      "Mathematics & Computing (5 Years, Bachelor of Science and Master of Science (Dual Degree))",
    value: 90,
  },
  {
    label: "Applied Geology (5 Years, Integrated M.Tech)",
    value: 91,
  },
  {
    label: "Applied Geophysics (5 Years, Integrated M.Tech)",
    value: 92,
  },
  {
    label: "Environmental Engg. (4 Years, B.Tech)",
    value: 93,
  },
  {
    label: "Mathematics and Computing (5 Years, Integrated M.Tech)",
    value: 94,
  },
  {
    label: "Mineral and Metallurgical Engg. (4 Years, B.Tech)",
    value: 95,
  },
  {
    label: "Mining Machinery Engg. (4 Years, B.Tech)",
    value: 96,
  },
  {
    label: "Petroleum Engg. (4 Years, B.Tech)",
    value: 97,
  },
  {
    label:
      "Biochemical Engg. with M.Tech. in Biochemical Engg. and Biotechnology (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 98,
  },
  {
    label:
      "Bioengineering with M.Tech in Biomedical Technology (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 99,
  },
  {
    label: "Ceramic Engg. (4 Years, B.Tech)",
    value: 100,
  },
  {
    label: "Ceramic Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 101,
  },
  {
    label: "Civil Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 102,
  },
  {
    label:
      "Electrical Engg. with M.Tech. in Power Electronics (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 103,
  },
  {
    label: "Electronics Engg. (4 Years, B.Tech)",
    value: 104,
  },
  {
    label: "Engg. Physics (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 105,
  },
  {
    label: "Industrial Chemistry (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 106,
  },
  {
    label:
      "Materials Science and Technology (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 107,
  },
  {
    label: "Mechanical Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 108,
  },
  {
    label: "Metallurgical Engg. (4 Years, B.Tech)",
    value: 109,
  },
  {
    label: "Metallurgical Engg. (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 110,
  },
  {
    label: "Pharmaceutical Engg. & Technology (4 Years, B.Tech)",
    value: 111,
  },
  {
    label:
      "Pharmaceutical Engg. & Technology (5 Years, B.Tech and M.Tech (Dual Degree))",
    value: 112,
  },
  {
    label: "Chemical Science and Technology (4 Years, B.Tech)",
    value: 113,
  },
  {
    label: "Data Science and Artificial Intelligence (4 Years, B.Tech)",
    value: 114,
  },
  {
    label: "Electronics and Electrical Engg. (4 Years, B.Tech)",
    value: 115,
  },
];

export const numOfCollegesDisplay = [
  {
    name: "20",
    value: "20",
  },
  {
    name: "30",
    value: "30",
  },
  {
    name: "40",
    value: "40",
  },
  {
    name: "50",
    value: "50",
  },
  {
    name: "100",
    value: "100",
  },
];
