import React, { Component } from "react";
import Modal from "react-modal";
import cancel from "../../Images/cancel.png";
import "../Footer/Modal.css";

Modal.setAppElement("body");
export default class MessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  render() {
    if (this.props.modalIsOpen) {
      return (
        <div>
          <Modal
            shouldCloseOnOverlayClick={true}
            isOpen={this.props.modalIsOpen}
            onRequestClose={this.props.closeModal}
            className="ModalForm_content"
            contentLabel="Example Modal"
          >
            <div className="row ml-0 mr-0 mt-3 justify-content-center">
              <div className="col-md-10 col-9 text-left"></div>
              <div className="col-md-2 col-3">
                <button
                  onClick={this.props.closeModal}
                  className="ModelPP_closeBtn"
                >
                  <img src={cancel} alt="close" className="ModelPP_closeImg" />
                </button>
              </div>
            </div>
            <hr className="mt-2" />
            <p className="pl-md-2 pl-2 text-left">
              <p>
                Confused about the documents required for JoSAA registration?
              </p>
              <p>
                Want to know more on admission process, college fees,
                placements, choice form filling?
              </p>
              <p>Don’t worry, talk with our experts.</p>
            </p>
            <a href="/admissionconsulting/">
              <button className="mt-5 KnowMore_Btn">Know More</button>
            </a>
          </Modal>
        </div>
      );
    } else {
      this.props.closeModal();
      return null;
    }
  }
}
