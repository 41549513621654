/*
for single select
{
  name:"",
  value:""
}

for multi select
{
  label:"",
  value:""
}
*/
export const numOfCollegesDisplay = [
  {
    name: "20",
    value: "20",
  },
  {
    name: "30",
    value: "30",
  },
  {
    name: "40",
    value: "40",
  },
  {
    name: "50",
    value: "50",
  },
  {
    name: "100",
    value: "100",
  },
];
export const categories = [
  {
    name: "OPEN",
    value: "OPEN",
  },
  {
    name: "SC",
    value: "SC",
  },
  {
    name: "ST",
    value: "ST",
  },
  {
    name: "VJ",
    value: "VJ",
  },
  {
    name: "NT1",
    value: "NT1",
  },
  {
    name: "NT2",
    value: "NT2",
  },
  {
    name: "NT3",
    value: "NT3",
  },

  {
    name: "OBC",
    value: "OBC",
  },

  {
    name: "TFWS",
    value: "TFWS",
  },
  {
    name: "EWS",
    value: "EWS",
  },
  {
    name: "PWD",
    value: "PWD",
  },
  {
    name: "DEF",
    value: "DEF",
  },
  {
    name: "MINORITY",
    value: "MI",
  },
  {
    name: "ORPHAN",
    value: "ORPHAN",
  },
];

export const branches = [
  {
    label: "Civil Engineering",
    value: "19110",
  },
  {
    label: "Computer Science and Engineering/ Computer Engineering/ Computer Science and Technology/ Computer Technology/ Computer Science and Information Technology",
    value: "24210,24510,25410,25110,56810",
  },
  {
    label: "Information Technology",
    value: "24610",
  },
  {
    label: "Electrical Engineering",
    value: "29310",
  },
  {
    label: "Electronics and Telecommunication Engg/ Electronics and Communication Engineering",
    value: "37210,37010",
  },
  {
    label: "Instrumentation Engineering",
    value: "46610",
  },
  {
    label: "Mechanical Engineering",
    value: "61210",
  },
  {
    label: "Food Technology/ Food Engineering and Technology/ Food Technology And Management",
    value: "50310,53410,50410",
  },
  {
    label: "Oil and Paints Technology",
    value: "51710",
  },
  {
    label: "Petro Chemical Engineering/ Petro Chemical Technology",
    value: "52710,52810",
  },
  {
    label: "Electrical Engg[Electronics and Power]",
    value: "35610",
  },
  {
    label: "Artificial Intelligence and Data Science",
    value: "99510, 26310",
  },
  {
    label: "Chemical Engineering",
    value: "50710",
  },
  {
    label: "Textile Engineering / Technology",
    value: "89310,89610",
  },
  {
    label: "Production Engineering/ Production Engineering[Sandwich]",
    value: "60610,62610",
  },
  {
    label: "Pharmaceutical and Fine Chemical Technology",
    value: "81610",
  },
  {
    label: "Electronics and Computer Engineering",
    value: "84410",
  },
  {
    label: "Agricultural Engineering",
    value: "01110",
  },
  {
    label: "Plastic and Polymer Engineering/ Plastic and Polymer Technology",
    value: "50110,51010",
  },
  {
    label:
      "Computer Science and Engineering(Artificial Intelligence and Machine Learning)",
    value: "91110",
  },
  {
    label: "Electrical and Electronics Engineering",
    value: "29710",
  },
  {
    label: "Electrical Engg [Electrical and Power]",
    value: "29810",
  },
  {
    label: "Electronics Engineering",
    value: "37610",
  },
  {
    label: "Electrical and Instrumentation Engineering",
    value: "57510",
  },
  {
    label: "Safety and Fire Engineering",
    value: "91410",
  },
  {
    label: "Data Science/ Data Engineering",
    value: "91710,92410",
  },
  {
    label: "Dyestuff Technology",
    value: "51110",
  },
  {
    label: "Oil,Oleochemicals and Surfactants Technology",
    value: "51210",
  },
  {
    label: "Pharmaceuticals Chemistry and Technology",
    value: "51310",
  },
  {
    label: "Fibres and Textile Processing Technology",
    value: "51410",
  },
  {
    label: "Polymer Engineering and Technology",
    value: "51910",
  },
  {
    label: "Surface Coating Technology",
    value: "72910",
  },
  {
    label: "Mechatronics Engineering",
    value: "62410",
  },
  {
    label: "Civil and infrastructure Engineering",
    value: "91810",
  },
  {
    label: "Bio Medical Engineering",
    value: "46110",
  },
  {
    label:
      "Computer Science and Engineering (Internet of Things and Cyber Security Including Block Chain Technology)",
    value: "92010",
  },
  {
    label: "Cyber Security",
    value: "25210",
  },
  {
    label: "Electronics and Computer Science",
    value: "90010",
  },
  {
    label: "Automobile Engineering",
    value: "60210",
  },
  {
    label: "Computer Science and Engineering(Data Science)",
    value: "91210",
  },
  {
    label: "Internet of Things (IoT)",
    value: "25310",
  },
  {
    label: "Mechanical and Mechatronics Engineering (Additive Manufacturing)",
    value: "25910",
  },
  {
    label: "Computer Science and Engineering(Cyber Security)",
    value: "91010",
  },
  {
    label: "Artificial Intelligence and Machine Learning",
    value: "92110",
  },
  {
    label: "Automation and Robotics",
    value: "91610",
  },
  {
    label: "Computer Science and Design",
    value: "25710",
  },
  {
    label: "Paper and Pulp Technology",
    value: "52410",
  },
  {
    label: "Oil Technology",
    value: "52910",
  },
  {
    label: "Industrial Engineering",
    value: "58910",
  },
  {
    label: "Computer Science and Engineering (Cyber Security)",
    value: "26010",
  },
  {
    label: "Computer Science and Engineering (IoT)",
    value: "26710",
  },
  {
    label: "Artificial Intelligence",
    value: "99710",
  },
  {
    label: "Aeronautical Engineering",
    value: "00210",
  },
  {
    label: "Bio Technology",
    value: "08210",
  },
  {
    label: "Industrial IoT",
    value: "26510",
  },
  {
    label: "Robotics and Artificial Intelligence",
    value: "26610",
  },
  {
    label: "Mining Engineering",
    value: "70110",
  },
  {
    label: "Plastic Technology",
    value: "50810",
  },
  {
    label: "Oil Fats and Waxes Technology",
    value: "51810",
  },
  {
    label: "Paints Technology",
    value: "52310",
  },
  {
    label: "Instrumentation and Control Engineering",
    value: "46410",
  },
  {
    label: "Robotics and Automation",
    value: "21910",
  },
  {
    label: "Structural Engineering",
    value: "91510",
  },
  {
    label: "Robotics",
    value: "99410",
  },
  {
    label: "Civil and Environmental Engineering",
    value: "92210",
  },
  {
    label: "Manufacturing Science and Engineering",
    value: "62710",
  },
  {
    label: "Metallurgy and Material Technology",
    value: "69410",
  },
  {
    label: "Computer Science and Business Systems",
    value: "26210",
  },
  {
    label: "Computer Science and Engineering (Artificial Intelligence)",
    value: "91310",
  },
  {
    label: "Computer Engineering (Regional Language)",
    value: "27110",
  },
  {
    label: "Automotive Technology",
    value: "26410",
  },
  {
    label: "Fashion Technology",
    value: "57010",
  },
  {
    label: "Man Made Textile Technology",
    value: "88610",
  },
  {
    label: "Textile Chemistry",
    value: "89010",
  },
  {
    label: "Textile Plant Engineering",
    value: "89510",
  },
  {
    label: "Mechanical & Automation Engineering",
    value: "61510",
  },
  {
    label:
      "Computer Science and Engineering (Artificial Intelligence and Data Science)",
    value: "92510",
  },
  {
    label: "Printing Technology",
    value: "84310",
  },
  {
    label: "Mechanical Engineering[Sandwich]",
    value: "62510",
  },
  {
    label:
      "Electronics and Telecommuincation Engg [Technologynician Electronic Radio]",
    value: "56710",
  },
  {
    label: "Silk Technology",
    value: "92310",
  },
];

export const region = [
  {
    name: "Akola, Amravati,Buldana, Washim,Yavatmal",
    value: "AABWY",
  },
  {
    name: "Aurangabad, Beed, Jalna, Osmanabad",
    value: "ABJO",
  },

  {
    name: "Hingoli, Latur, Nanded,Parbhani",
    value: "HLNP",
  },
  {
    name: "Mumbai City,Mumbai Suburban",
    value: "MM",
  },
  {
    name: "Ratnagiri, Raigad, Palghar,Sindhudurg ,Thane",
    value: "RRPST",
  },
  {
    name: "Bhandara, Gondia, Nagpur, Wardha",
    value: "BGNW",
  },
  {
    name: "Chandrapur, Ghadchiroli",
    value: "CG",
  },
  {
    name: "Dhule,Jalgaon,Nandurbar",
    value: "DJN",
  },
  {
    name: "Ahmednagar, Nashik, Pune",
    value: "ANP",
  },
  {
    name: "Kolhapur, Sangli, Satara",
    value: "KSS",
  },
  {
    name: "Solapur",
    value: "SOL",
  },
];

export const collegeStatus = [
  {
    name: "Government",
    value: "GOV",
  },
  {
    name: "Non-Government Autonomous",
    value: "UAA",
  },

  {
    name: "ALL",
    value: "ALL",
  },
];
export const miCategoriesTypes = [
  {
    name: "Linguistic Minority - Hindi",
    value: "LMH",
  },

  {
    name: "Religious Minority - Muslim",
    value: "RMM",
  },

  {
    name: "Linguistic Minority - Gujarathi(Jain)",
    value: "LMGJ",
  },
  {
    name: "Linguistic Minority - Sindhi",
    value: "LMS",
  },
  {
    name: "Religious Minority - Christian",
    value: "RMC",
  },
  {
    name: "Linguistic Minority - Gujarathi",
    value: "LMG",
  },
  {
    name: "Linguistic Minority - Malyalam",
    value: "LMM",
  },
  {
    name: "Religious Minority - Roman Catholics",
    value: "RMRC",
  },
  {
    name: "Linguistic Minority - Punjabi",
    value: "LMP",
  },
  {
    name: "Linguistic Minority - Tamil",
    value: "LMT",
  },
  {
    name: "Religious Minority - Jain",
    value: "RMJ",
  },
  {
    name: "Linguistic Minority - Gujar",
    value: "LMGU",
  },
];
