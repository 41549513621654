import React, { Component, Fragment } from "react";
import "./AdmissionConsultingPage.css";
import { Helmet } from "react-helmet-async";
import Navbar from "../Navbar/Navbar";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import homepageimage from "../../Images/Home-Page-Thumbnail.png";

export default class AdmissionConsultingPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index,follow" />

          <title>Graduhat- Admission Consulting | JEE Main/Adv | MHT CET</title>
          <meta
            name="description"
            content="We help you select best possible colleges based on college’s rank, placements, fees, reputation, branch, your location preference etc."
          />
          <meta
            name="keywords"
            content="Graduhat,College Predictor,Engineering College Predictor,Latest News,JEE Mains,JEE Advanced,MHT-CET,josaa,IIT,NIT,IIIT,GFTI,NTA"
          />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content=" Graduhat- Admission Consulting | JEE Main/Adv | MHT CET"
          />
          <meta
            property="og:description"
            content="We help you select best possible colleges based on college’s rank, placements, fees, reputation, branch, your location preference etc."
          />
          <meta property="og:url" content="https://www.graduhat.com/" />
          <meta property="og:image" content={homepageimage} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@graduhat" />
          <meta name="twitter:creator" content="@graduhat" />
          <meta
            name="twitter:title"
            content=" Graduhat- Admission Consulting | JEE Main/Adv | MHT CET"
          />
          <meta
            name="twitter:description"
            content="We help you select best possible colleges based on college’s rank, placements, fees, reputation, branch, your location preference etc."
          />
          <meta name="twitter:image" content={homepageimage} />
        </Helmet>
        <Navbar isForm={true} />
        <div className="row mt-4 AdmissionConsultingPage_row justify-content-center">
          <div className="col-11">
            <h1 className=" AdmissionConsultingPage_headerText">
              Admission Consulting
            </h1>
            <h2 className=" AdmissionConsultingPage_headerTextSub">
              JEE Main | JEE Advanced | MHT- CET
            </h2>
          </div>
        </div>
        <div className="row AdmissionConsultingPage_row mt-3 justify-content-center">
          <div className="col-10 pt-3 pb-3 AdmissionConsultingPage_Card">
            <h3 className="AdmissionConsultingPage_opHeader">
              What do we provide?
            </h3>
            <div className="row AdmissionConsultingPage_row justify-content-center">
              <div className="col-12">
                <div className="row">
                  <div className="col-1 text-right">
                    <FaCheck color="#00c300" />
                  </div>
                  <div className="col-10 text-left">
                    <p className="AdmissionConsultingPage_pointText">
                      One-to-one video call session with our experts.
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1 text-right">
                    <FaCheck color="#00c300" />
                  </div>
                  <div className="col-10 text-left">
                    <p className="AdmissionConsultingPage_pointText">
                      <b>Unlimited</b> chat support for all your admission
                      related queries till the last admission round.
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1 text-right">
                    <FaCheck color="#00c300" />
                  </div>
                  <div className="col-10 text-left">
                    <p className="AdmissionConsultingPage_pointText">
                      Personalised college list based on college’s rank,
                      placements, fees, reputation, your branch and location
                      preference.
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1 text-right">
                    <FaCheck color="#00c300" />
                  </div>
                  <div className="col-10 text-left">
                    <p className="AdmissionConsultingPage_pointText">
                      Guidance on how to register for JEE Counselling rounds
                      (JoSSA) and MHT- CET Cap round.
                    </p>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-1 text-right">
                    <FaCheck color="#00c300" />
                  </div>
                  <div className="col-10 text-left">
                    <p className="AdmissionConsultingPage_pointText">
                      List of documents and their alternatives required for the
                      registration.{" "}
                      <b>
                        (Our experts have been part of official document
                        verification team of MHT CET.)
                      </b>
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1 text-right">
                    <FaCheck color="#00c300" />
                  </div>
                  <div className="col-10 text-left">
                    <p className="AdmissionConsultingPage_pointText">
                      Guidance on how to fill out the option/choice form, how to
                      change the college options for the next rounds, how to
                      freeze your allotted college, etc.
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-1 text-right">
                    <FaCheck color="#00c300" />
                  </div>
                  <div className="col-10 text-left">
                    <p className="AdmissionConsultingPage_pointText">
                      Insights for additional/special admission rounds.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row AdmissionConsultingPage_row mt-5 justify-content-center">
          <div className="col-12">
            <h3 className="AdmissionConsultingPage_opHeader">Our Plans</h3>
            <div className="row AdmissionConsultingPage_row justify-content-center mt-2">
              <div className="col-12">
                <div className="row AdmissionConsultingPage_row justify-content-around">
                  <div className="col-12 col-md-3 AdmissionConsultingPage_Card pt-4 pb-5 mb-3">
                    <h4 className="AdmissionConsultingPage_cardHeader">
                      Plan 1
                    </h4>
                    <h4 className="AdmissionConsult_ExtraMargin AdmissionConsultingPage_amtText">
                      ₹ 250
                    </h4>
                    <p>Features:</p>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">
                          <b>Unlimited</b> chart support for your any admission
                          related query till your last admission round.
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">One-to-one video call with experts. </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p
                          style={{
                            color: "rgb(67,98,238)",
                            fontWeight: "bold",
                          }}
                        >
                          Number of Video Calls : 1
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">Personalised list of colleges.</p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">
                          Guidance for document requirements, JoSAA/CAP round
                          registration, option/choice form filling,
                          additional/special admission rounds.
                        </p>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "/plan",
                        state: "1",
                      }}
                    >
                      <button className="AdmissionConsultingHomepage_kmButton">
                        Select Plan
                      </button>
                    </Link>
                  </div>
                  <div className="col-12 col-md-3 AdmissionConsultingPage_Card pt-4 pb-5 mb-3">
                    <h4 className="AdmissionConsultingPage_cardHeader">
                      Plan 2
                    </h4>
                    <p className="AdmissionConsulting_StrikedText">
                      <s>₹ 350</s>
                    </p>
                    <h4 className="AdmissionConsultingPage_amtText">₹ 300</h4>
                    <p>Features:</p>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">
                          <b>Unlimited</b> chart support for your any admission
                          related query till your last admission round.
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">One-to-one video call with experts. </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p
                          style={{
                            color: "rgb(247,37,132)",
                            fontWeight: "bold",
                          }}
                        >
                          Number of Video Calls : 2
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">Personalised list of colleges.</p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">
                          Guidance for document requirements, JoSAA/CAP round
                          registration, option/choice form filling,
                          additional/special admission rounds.
                        </p>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "/plan",
                        state: "2",
                      }}
                    >
                      <button className="AdmissionConsultingHomepage_kmButton">
                        Select Plan
                      </button>
                    </Link>
                  </div>
                  <div className="col-12 col-md-3 AdmissionConsultingPage_Card pt-4 pb-5 mb-3">
                    <h4 className="AdmissionConsultingPage_cardHeader">
                      Plan 3
                    </h4>
                    <p className="AdmissionConsulting_StrikedText">
                      <s>₹ 450</s>
                    </p>
                    <h4 className="AdmissionConsultingPage_amtText">₹ 400</h4>
                    <p>Features:</p>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">
                          <b>Unlimited</b> chart support for your any admission
                          related query till your last admission round.
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">One-to-one video call with experts. </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p
                          style={{
                            color: "rgb(114,9,183)",
                            fontWeight: "bold",
                          }}
                        >
                          Number of Video Calls : 3
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">Personalised list of colleges.</p>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-1 text-right">
                        <FaCheck color="#00c300" />
                      </div>
                      <div className="col-10 text-left">
                        <p className="">
                          Guidance for document requirements, JoSAA/CAP round
                          registration, option/choice form filling,
                          additional/special admission rounds.
                        </p>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "/plan",
                        state: "3",
                      }}
                    >
                      <button className="AdmissionConsultingHomepage_kmButton">
                        Select Plan
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row AdmissionConsultingPage_rowMargin justify-content-center text-left">
              <div className="col-11  AdmissionConsultingPage_termsText justify-content-start">
                *Terms and Conditions apply for all the plans mentioned above.
              </div>
            </div>
          </div>
        </div>
        <div className="row AdmissionConsultingPage_rowMargin justify-content-center">
          <div className="">
            <p className="AdmissionConsultingPage_Footer">
              Confused if this plan is for you? Don’t worry we understand your
              confusions, talk with us on{" "}
              <a
                href="https://www.instagram.com/graduhatindia/"
                className="AdmissionConsulting_instalink"
              >
                Instagram
              </a>{" "}
              and clear all your doubts about our consultancy service.
            </p>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </Fragment>
    );
  }
}
