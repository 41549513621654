import React, { Component, Fragment } from "react";
import { fetchAllNews } from "../../DataServices/DataServices";
import "./News.css";
class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allNews: [],
      showError: false,
    };
  }
  async loadData() {
    await fetchAllNews()
      .then((response) => {
        if (response.error) {
          this.setState({ showError: true });
        } else {
          if (
            response.data.indexOf("Error") === -1 &&
            response.data.indexOf("<html>") === -1
          )
            this.setState({ allNews: response.data });
        }
      })
      .catch((error) => {
        this.setState({
          showError: true,
        });
      });
  }
  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <Fragment>
        <div className="row justify-content-center News_header">
          <div className="col-12 col-md-10 text-center News_Text ">
            <h2 className="News_headerText">News</h2>
            <p className="News_subheaderText">
              Find Latest News from the official JOSAA, NTA, MAHA CET Cell
              websites, and other Government officials for Exam dates, Results,
              Applications, Choice/Option form filling, and all other important
              notifications and dates.
            </p>
          </div>
        </div>
        <div className="row justify-content-center News_headerTable mt-1 ">
          {this.state.showError ? (
            <div className="alert alert-warning mt-3" role="alert">
              Please Come Back Later.
            </div>
          ) : (
            <Fragment>
              <div className="table-responsive col-12 col-md-10 News_Table">
                <table className="table">
                  <thead className="News_tableHeader">
                    <tr>
                      <td>Date</td>
                      <td>News</td>
                      <td>Exam</td>
                    </tr>
                  </thead>
                  <tbody className="News_tableBody">
                    {this.state.allNews.map((news, index) => (
                      <tr key={index}>
                        <td>{news.DATE}</td>
                        <td className="">
                          <a
                            href={news.LINK}
                            className="News_newsTitle"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {news.NEWS}
                          </a>
                        </td>
                        <td>{news.EXAM}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <small className="mobileText mt-2">
                Scroll table for more news.
              </small>
            </Fragment>
          )}
        </div>

        <hr />
      </Fragment>
    );
  }
}
export default News;
