import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { MdKeyboardBackspace } from "react-icons/md";
class NotFound extends Component {
  state = {};
  handleClick() {
    window.history.back();
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>404 Page not found - Graduhat</title>
          <meta content="noindex,follow" name="robots" />
          <meta
            content="404 Page not found"
            data-rh="true"
            name="description"
          />
          <meta content="en_US" data-rh="true" property="og:locale" />
          <meta content="website" data-rh="true" property="og:type" />
          <meta content="404 Page not found" property="og:title" />
          <meta
            content="404 Page not found"
            data-rh="true"
            property="og:description"
          />
          <meta
            content="summary_large_image"
            data-rh="true"
            name="twitter:card"
          />
          <meta content="404 Page not found" name="twitter:title" />
          <meta
            content="404 Page not found"
            data-rh="true"
            name="twitter:description"
          />
        </Helmet>
        <div className="container-fluid">
          <div
            className="jumbotron mt-2 text-center notfoundPage"
            style={{ backgroundColor: "rgb(47, 85, 151)", color: "white" }}
          >
            <h5 className="display-3 my-2 notfoundText">
              404 : Page not found
            </h5>
            <hr />
            Sorry, wrong URL.
            <hr />
            <button className="btn btn-light" onClick={this.handleClick}>
              <span className="mr-2">
                <MdKeyboardBackspace
                  style={{ margin: -1, padding: -1 }}
                  color="black"
                ></MdKeyboardBackspace>
              </span>
              Click here to go back
            </button>
          </div>
          <p>Other Links</p>
          <a className="Forms_links" href="/">
            HomePage
          </a>
          <br />
          <a className="Forms_links" href="/jee-advanced-college-predictor/">
            JEE Advanced College Predictor
          </a>
          <br />
          <a className="Forms_links" href="/mht-cet-college-predictor/">
            MHT-CET College Predictor
          </a>
          <br />
          <a className="Forms_links" href="/jee-main-college-predictor/">
            JEE Mains College Predictor
          </a>
        </div>
      </Fragment>
    );
  }
}

export default NotFound;
