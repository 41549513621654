import React, { Component, Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import "./CETForm.css";
import {
  branches,
  categories,
  collegeStatus,
  numOfCollegesDisplay,
  region,
  miCategoriesTypes,
} from "./cetData";
import {MultiSelect} from "react-multi-select-component";
import Footer from "../Footer/Footer";
import step2 from "../../Images/step2.png";
import step3 from "../../Images/step3.png";
import clearbtn from "../../Images/btnClear.svg";
import btnPredict from "../../Images/btnPredict.png";
import cetmetaimage from "../../Images/MHT-CET-College-Predictor-Thumbnail.png";
import { fetchCET } from "../../DataServices/DataServices";
import ReCAPTCHA from "react-google-recaptcha";
import mobilestep2 from "../../Images/mobilestep2.png";
import mobilestep3 from "../../Images/mobilestep3.png";
import { Helmet } from "react-helmet-async";
import { FaArrowRight } from "react-icons/fa";
import MessageModal from "./MessageModal";
const KEY = "6LfPRZkbAAAAAJYW1TQMP11aRbnTxxnQGrGa6Opq";
export default class CETForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: categories,
      branches: branches,
      miCategoriesTypes: miCategoriesTypes,
      collegeStatus: collegeStatus,
      region: region,
      numOfCollegesDisplay: numOfCollegesDisplay,
      selectedBranches: [],
      selectedTypeOfCollege: [],
      Cet_catergory: "",
      Cet_rank: "",
      Cet_noc: "",
      Cet_region: "",
      Cet_collegeStatus: "",
      gender: "",
      Cet_miType: "",
      showResults: false,
      results: [],
      showError: false,
      diplomaSelected: false,
      aiSelected: false,
      cetSelected: true,
      MICategorySelected: false,
      male: false,
      female: false,
      other: false,
      verified: false,
      captchaValue: "",
      loading: false,
      clicked: false,
      verifiedcode: "1",
      modalIsOpen: false,
      requestcode: "1",
    };
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "Cet_catergory") {
      if (e.target.value === "MI") {
        this.setState({
          MICategorySelected: true,
        });
      } else {
        this.setState({
          MICategorySelected: false,
        });
      }
    }
    if (e.target.name === "gender") {
      if (e.target.id === "male") {
        this.setState({
          male: true,
          female: false,
          other: false,
        });
      }
      if (e.target.id === "female") {
        this.setState({
          male: false,
          female: true,
          other: false,
        });
      }
      if (e.target.id === "other") {
        this.setState({
          male: false,
          female: false,
          other: true,
        });
      }
    }
  };
  captchaOnChange = async (value) => {
    var current = new Date();
    var t_ver = current.toUTCString().split(" ")[4].split(":").join("");
    var m_ver = (current.getUTCMonth() + 1).toString();
    var d_ver = current.getUTCDate().toString();
    var k_ver = "21";
    var firstcode = k_ver.concat(m_ver, d_ver, t_ver);
    this.setState({
      captchaValue: value,
      clicked: true,
      verifiedcode: firstcode,
    });
  };
  captchaonExpired = () => {
    this.setState({
      captchaValue: "",
      clicked: false,
    });
  };
  handleSeatType = (e) => {
    switch (e.target.value) {
      case "CET":
        this.setState({
          diplomaSelected: false,
          aiSelected: false,
          cetSelected: true,
          showError: false,
          loading: false,
          showResults: false,
        });
        break;
      case "AI":
        this.setState({
          diplomaSelected: false,
          showError: false,
          aiSelected: true,
          showResults: false,
          loading: false,
          cetSelected: false,
        });
        break;
      case "DIPLOMA":
        this.setState({
          diplomaSelected: true,
          aiSelected: false,
          showResults: false,
          loading: false,
          cetSelected: false,
          showError: false,
        });
        break;
      default:
        break;
    }
    // this.setState({ [e.target.name]: e.target.value });
  };
  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      selectedBranches: [],
      selectedTypeOfCollege: [],
      Cet_catergory: "",
      Cet_rank: "",
      Cet_noc: "",
      Cet_region: "",
      Cet_collegeStatus: "",
      gender: "",
      Cet_miType: "",
      showResults: false,
      results: [],
      diplomaSelected: false,
      aiSelected: false,
      cetSelected: true,
      MICategorySelected: false,
      male: false,
      female: false,
      other: false,
      loading: false,
      verified: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    if (this.state.diplomaSelected) {
      if (this.state.Cet_noc === "") {
        alert("Please select number of colleges to display.");
      } else {
        data.append("exam", "DIPLOMA");
        data.append("rank", this.state.Cet_rank);
        data.append("lmt", this.state.Cet_noc);
        data.append("cat", "null");
        data.append("gender", "null");
        data.append("reg", "null");
        data.append("clgsts", "null");
        data.append("branch", "null");
        data.append("mi_type", "null");
        this.fetchData(data);
      }
    } else if (this.state.aiSelected) {
      if (this.state.Cet_noc === "") {
        alert("Please select number of colleges to display.");
      } else if (this.state.selectedBranches.length === 0) {
        alert("Please select at least one branch.");
      } else {
        data.append("exam", "AI");
        data.append("rank", this.state.Cet_rank);
        data.append("lmt", this.state.Cet_noc);
        data.append("cat", "null");
        data.append("gender", "null");
        data.append("reg", "null");
        data.append("clgsts", "null");
        data.append("mi_type", "null");

        let branch = [];
        this.state.selectedBranches.map((b) => branch.push(b.value));
        data.append("branch", branch.join(","));
        this.fetchData(data);
      }
    } else if (this.state.cetSelected) {
      if (this.state.Cet_noc === "") {
        alert("Please select number of colleges to display.");
      } else if (this.state.Cet_catergory === "") {
        alert("Please select Category");
      } else if (this.state.Cet_region === "") {
        alert("Please select Region");
      } else if (this.state.Cet_collegeStatus === "") {
        alert("Please select College Status.");
      } else if (this.state.selectedBranches.length === 0) {
        alert("Please select at least one branch.");
      } else {
        data.append("exam", "CET");
        data.append("rank", this.state.Cet_rank);
        data.append("lmt", this.state.Cet_noc);
        data.append("cat", this.state.Cet_catergory);
        data.append("gender", this.state.gender);
        data.append("reg", this.state.Cet_region);
        data.append("clgsts", this.state.Cet_collegeStatus);
        if (this.state.Cet_catergory === "MI") {
          if (this.state.Cet_noc === "") {
            alert("Please select Minority Category");
          } else {
            data.append("mi_type", this.state.Cet_miType);
          }
        } else {
          data.append("mi_type", "NULL");
        }
        let branch = [];
        this.state.selectedBranches.map((b) => branch.push(b.value));
        data.append("branch", branch.join(","));
        this.fetchData(data);
      }
    }
  };
  fetchData = async (data) => {
    data.append("g-recaptcha-response", this.state.captchaValue);
    data.append("verifiedcode", this.state.verifiedcode);
    data.append("requestcode", this.state.requestcode);
    if (this.state.clicked) {
      document.getElementById("JeeResults").scrollIntoView();
      this.setState({
        loading: true,
      });
      await fetchCET(data)
        .then((response) => {
          if (response.error) {
            alert(response.error);
            this.setState({
              showResults: false,
              showError: true,
              loading: false,
            });
          } else {
            if (response.data.indexOf("Error") !== -1) {
              alert("Captcha timedout. Refresh the page.");
            } else {
              this.setState({
                showResults: true,
                showError: false,
                results: response.data,
                loading: false,
              });
            }
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            showError: true,
            error: error,
          });
        });
    } else {
      alert("Captcha not verified");
    }
    var date = new Date();
    var t_req = date.toUTCString().split(" ")[4].split(":").join("");
    var m_req = (date.getUTCMonth() + 1).toString();
    var d_req = date.getUTCDate().toString();
    var k_req = "21";
    var requesttime = k_req.concat(m_req, d_req, t_req);
    this.setState({
      requestcode: requesttime,
    });
    setTimeout(() => {
      this.setState({
        modalIsOpen: true,
      });
    }, 15000);
  };

  ShowResultsTable = (results) => {
    if (results) {
      if (results.length === 0) {
        return (
          <div className="row justify-content-center CetForm_row mt-1 mb-5">
            <span className="alert alert-info">
              No Results Found For Given Data.
            </span>
          </div>
        );
      }
      return (
        <div className="row justify-content-center CetForm_row mt-1 mb-5">
          <div className="table-responsive col-12 col-md-10 CetResult_Table">
            <table className="table">
              <thead className="CetResult_tableHeader">
                <tr>
                  <td>College</td>
                  <td>Branch Name</td>
                  <td>Predicted Cuttoff Range</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody className="CetResult_tableBody">
                {results.map((result, index) => (
                  <tr key={index}>
                    <td>{result.college_name}</td>
                    <td className="">{result.branch_name}</td>
                    <td>{result.range}</td>
                    <td>{result.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row justify-content-center CetForm_row mt-1 mb-5">
          <span className="alert alert-info">
            No Results Found. Please Try again later.
          </span>
        </div>
      );
    }
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  displayCet = () => {
    return (
      <Fragment>
        <div className="form-row justify-content-between  align-items-center mt-3 mt-md-2">
          <div className="col-12 col-md-5">
            <label className="CetForm_labels" htmlFor="Cet_rank">
              Rank:
            </label>{" "}
            <span className="text-danger">*</span>
            <input
              onChange={this.handleChange}
              type="number"
              min="0"
              value={this.state.Cet_rank}
              name="Cet_rank"
              id="Cet_rank"
              className="form-control"
              placeholder="Enter Rank"
              required
            />
          </div>
          <div className="col-12 col-md-5 mt-3 mt-md-0">
            <label className="CetForm_labels" htmlFor="Cet_category">
              Category:
            </label>{" "}
            <span className="text-danger">*</span>
            <select
              name="Cet_catergory"
              id="Cet_catergory"
              required
              value={this.state.Cet_catergory}
              onChange={this.handleChange}
              className="form-control"
            >
              <option value="null">Select Category</option>
              {this.state.categories.map((c, index) => (
                <option key={index} value={c.value}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.MICategorySelected && (
          <div className="form-row align-items-center mt-3 mt-md-4">
            <div className="col-7"></div>
            <div className=" col-12 col-md-5 ">
              <label className="CetForm_labels" htmlFor="Cet_miType">
                Minority Type:
              </label>{" "}
              <span className="text-danger">*</span>
              <select
                name="Cet_miType"
                id="Cet_miType"
                value={this.state.Cet_miType}
                required
                onChange={this.handleChange}
                className="form-control"
              >
                <option value="null">Select Minority Type</option>
                {this.state.miCategoriesTypes.map((c, index) => (
                  <option key={index} value={c.value}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="form-row align-items-center mt-3 mt-md-4 justify-content-between">
          <div className="col-12">
            <label className="CetForm_labels" htmlFor="Cet_branch">
              Branch:
            </label>{" "}
            <span className="text-danger">*</span>
            <MultiSelect
              id="Cet_branch"
              options={this.state.branches}
              value={this.state.selectedBranches}
              onChange={(branch) =>
                this.setState({
                  selectedBranches: branch,
                })
              }
              filterOptions={(options, filter) => {
                if (!filter) {
                  return options;
                }
                const re = new RegExp(filter, "i");
                return options.filter(({ label }) => label && label.match(re));
              }}
              labelledBy="Branch"
            />
          </div>
        </div>
        <div className="form-row align-items-center mt-3 mt-md-4 justify-content-between">
          <div className="col-12 col-md-5">
            <label className="CetForm_labels" htmlFor="Cet_noc">
              Number of Colleges to Display:
            </label>{" "}
            <span className="text-danger">*</span>
            <select
              name="Cet_noc"
              id="Cet_noc"
              required
              value={this.state.Cet_noc}
              onChange={this.handleChange}
              className="form-control"
            >
              <option value="null">Select Number of Colleges to Display</option>
              {this.state.numOfCollegesDisplay.map((c, index) => (
                <option key={index} value={c.value}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-5 mt-3 mt-md-0">
            <label className="CetForm_labels" htmlFor="Cet_collegeStatus">
              College Status:
            </label>{" "}
            <span className="text-danger">*</span>
            <select
              name="Cet_collegeStatus"
              id="Cet_collegeStatus"
              value={this.state.Cet_collegeStatus}
              required
              onChange={this.handleChange}
              className="form-control"
            >
              <option value="null">Select Colleges Status</option>
              {this.state.collegeStatus.map((c, index) => (
                <option key={index} value={c.value}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-row mt-3 mt-md-4 align-items-center justify-content-between ">
          <div className="col-12 col-md-5">
            <label className="CetForm_labels" htmlFor="Cet_region">
              Home Region:
            </label>{" "}
            <span className="text-danger">*</span>
            <select
              value={this.state.Cet_region}
              name="Cet_region"
              id="Cet_region"
              required
              onChange={this.handleChange}
              className="form-control"
            >
              <option value="null">Select Home Region</option>
              {this.state.region.map((c, index) => (
                <option key={index} value={c.value}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-3 mt-md-4">
          <b> Gender:</b> <span className="text-danger">*</span>
        </div>
        <div className="form-row align-items-center ">
          <div className="col-4 col-md-3">
            <div className="form-row ml-1">
              <div className="col-3 col-md-2">
                <div className="form-check">
                  <input
                    onChange={this.handleChange}
                    className="form-check-input"
                    type="radio"
                    checked={this.state.male}
                    required
                    name="gender"
                    id="male"
                    value="G"
                  />
                </div>
              </div>
              <label className="CetForm_labels" htmlFor="male">
                Male
              </label>
            </div>
          </div>
          <div className="col-4 col-md-3">
            <div className="form-row">
              <div className="col-3 col-md-2">
                <div className="form-check">
                  <input
                    onChange={this.handleChange}
                    className="form-check-input"
                    type="radio"
                    required
                    name="gender"
                    id="female"
                    checked={this.state.female}
                    value="L"
                  />
                </div>
              </div>
              <label className="CetForm_labels" htmlFor="female">
                Female
              </label>
            </div>
          </div>
          <div className="col-4 col-md-3">
            <div className="form-row">
              <div className="col-3 col-md-2">
                <div className="form-check">
                  <input
                    onChange={this.handleChange}
                    className="form-check-input"
                    type="radio"
                    required
                    name="gender"
                    checked={this.state.other}
                    id="other"
                    value="G"
                  />
                </div>
              </div>
              <label className="CetForm_labels" htmlFor="other">
                Other
              </label>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  displayAI = () => {
    return (
      <Fragment>
        <div className="form-row  align-items-center mt-3 mt-md-2 justify-content-between">
          <div className="col-12 col-md-5">
            <label className="CetForm_labels" htmlFor="Cet_rank">
              Rank:
            </label>{" "}
            <span className="text-danger">*</span>
            <input
              onChange={this.handleChange}
              type="number"
              min="0"
              name="Cet_rank"
              value={this.state.Cet_rank}
              id="Cet_rank"
              className="form-control"
              placeholder="Enter Rank"
              required
            />
          </div>
          <div className="col-12 col-md-5 mt-3 mt-md-0">
            <label className="CetForm_labels" htmlFor="Cet_noc">
              Number of Colleges to Display:
            </label>{" "}
            <span className="text-danger">*</span>
            <select
              name="Cet_noc"
              id="Cet_noc"
              value={this.state.Cet_noc}
              required
              onChange={this.handleChange}
              className="form-control"
            >
              <option value="null">Select Number of Colleges to Display</option>
              {this.state.numOfCollegesDisplay.map((c, index) => (
                <option key={index} value={c.value}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-row align-items-center mt-3 mt-md-4">
          <div className="col-12">
            <label className="CetForm_labels" htmlFor="Cet_branch">
              Branch:
            </label>{" "}
            <span className="text-danger">*</span>
            <MultiSelect
              id="Cet_branch"
              options={this.state.branches}
              value={this.state.selectedBranches}
              onChange={(branch) =>
                this.setState({
                  selectedBranches: branch,
                })
              }
              filterOptions={(options, filter) => {
                if (!filter) {
                  return options;
                }
                const re = new RegExp(filter, "i");
                return options.filter(({ label }) => label && label.match(re));
              }}
              labelledBy="Branch"
            />
          </div>
        </div>
      </Fragment>
    );
  };
  displayDiploma = () => {
    return (
      <div className="form-row  align-items-center mt-3 mt-md-2 justify-content-between">
        <div className="col-12 col-md-5  ">
          <label className="CetForm_labels" htmlFor="Cet_rank">
            Rank:
          </label>{" "}
          <span className="text-danger">*</span>
          <input
            onChange={this.handleChange}
            type="number"
            min="0"
            name="Cet_rank"
            value={this.state.Cet_rank}
            id="Cet_rank"
            className="form-control"
            placeholder="Enter Rank"
            required
          />
        </div>
        <div className="col-12 col-md-5 mt-3 mt-md-0">
          <label className="CetForm_labels" htmlFor="Cet_noc">
            Number of Colleges to Display:
          </label>{" "}
          <span className="text-danger">*</span>
          <select
            value={this.state.Cet_noc}
            name="Cet_noc"
            id="Cet_noc"
            required
            onChange={this.handleChange}
            className="form-control"
          >
            <option value="null">Select Number of Colleges to Display</option>
            {this.state.numOfCollegesDisplay.map((c, index) => (
              <option key={index} value={c.value}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  scrollDown = () => {
    document.getElementById("VideoRow").scrollIntoView();
  };
  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="index,follow" />

          <title>MHT-CET College Predictor 2022- Graduhat | No Login</title>
          <meta
            name="description"
            content="Free College Predictor for MHT-CET and AI, Diploma seats based on Rank, Category, Gender & Branch without Login or Contact details"
          />
          <meta
            name="keywords"
            content="MHT-CET College Predictor 2022,Graduhat,AI,Diploma,cutoff,rank,gender,category,home university,branch,COEP,VJTI,SPCE,SPIT,WCE,PICT"
          />

          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="MHT-CET College Predictor 2022- Graduhat | No Login"
          />
          <meta
            property="og:description"
            content="Free College Predictor for MHT-CET and AI, Diploma seats based on Rank, Category, Gender & Branch without Login or Contact details"
          />
          <meta
            property="og:url"
            content="https://www.graduhat.com/mht-cet-college-predictor"
          />
          <meta property="og:image" content={cetmetaimage} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@graduhat" />
          <meta name="twitter:creator" content="@graduhat" />
          <meta
            name="twitter:title"
            content="MHT-CET College Predictor 2022- Graduhat | No Login"
          />
          <meta
            name="twitter:description"
            content="Free College Predictor for MHT-CET and AI, Diploma seats based on Rank, Category, Gender & Branch without Login or Contact details"
          />
          <meta name="twitter:image" content={cetmetaimage} />
        </Helmet>
        <Navbar isForm={true} />
        <div className="row mt-3 CetForm_row justify-content-center">
          <div className="col-md-10 col-12">
            <h1 className="CetForm_headerText text-center mb-0">
              MHT CET College Predictor 2022
            </h1>
            <p className="p-0 m-0 Cet_subText">
              Get College Predictions and Cutoff Ranks for all Maharashtra
              colleges based on your Rank, Category, Gender, Home University,
              and preferred Branch <b>without Login or Contact details.</b>
               Also, get College Predictions for Diploma and All India seats.
            </p>
          </div>
        </div>

        <hr />
        <div className="row align-items-start justify-content-center CetForm_row ">
          <div className="col-12 col-md-3">
            {this.state.showResults ? (
              <picture>
                <source
                  className="stepper1"
                  media="(max-width: 576px)"
                  srcSet={mobilestep3}
                />

                <img src={step3} alt="Step3" className="stepper2" />
              </picture>
            ) : (
              <picture>
                <source
                  className="stepper1"
                  media="(max-width: 576px)"
                  srcSet={mobilestep2}
                />

                <img src={step2} alt="Step2" className="stepper2" />
              </picture>
            )}
          </div>
          <div className="col-12 col-md-7 CetForm_formCol">
            <div className="text-left">
              {" "}
              <small>
                {" "}
                We Recommend to watch our Demo Video.{" "}
                <span className="VideoLink" onClick={this.scrollDown}>
                  Click Here
                </span>
              </small>
            </div>

            <form
              id="Cet_form"
              onSubmit={this.handleSubmit}
              onReset={this.handleClear}
              className="text-left"
            >
              <div className="">
                Seat Type: <span className="text-danger">*</span>
              </div>

              <div className="form-row mt-4 align-items-start ">
                <div className="col-12 col-md-4">
                  <div className="form-row justify-content-sm-start justify-content-md-center align-items-start">
                    <div className="col-2 col-md-2">
                      <div className="form-check">
                        <input
                          onChange={this.handleSeatType}
                          className="form-check-input"
                          type="radio"
                          required
                          name="seatType"
                          id="CET"
                          checked={this.state.cetSelected}
                          value="CET"
                        />
                      </div>
                    </div>
                    <label className="CetForm_labels col-10" htmlFor="CET">
                      MHT-CET Seats
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4 ">
                  <div className="form-row justify-content-sm-start justify-content-md-center align-items-start">
                    <div className="col-2 col-md-2">
                      <div className="form-check">
                        <input
                          onChange={this.handleSeatType}
                          className="form-check-input"
                          type="radio"
                          required
                          name="seatType"
                          id="AI"
                          value="AI"
                          checked={this.state.aiSelected}
                        />
                      </div>
                    </div>
                    <label className="CetForm_labels col-10" htmlFor="AI">
                      All India Seats
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-row justify-content-sm-start justify-content-md-center align-items-start">
                    <div className="col-2 col-md-2">
                      <div className="form-check">
                        <input
                          onChange={this.handleSeatType}
                          className="form-check-input"
                          type="radio"
                          required
                          name="seatType"
                          checked={this.state.diplomaSelected}
                          id="DIPLOMA"
                          value="DIPLOMA"
                        />
                      </div>
                    </div>
                    <label className="CetForm_labels col-10" htmlFor="DIPLOMA">
                      Diploma Seats
                    </label>
                  </div>
                </div>
              </div>
              {this.state.aiSelected ? this.displayAI() : <div></div>}
              {this.state.diplomaSelected ? this.displayDiploma() : <div></div>}
              {this.state.cetSelected ? this.displayCet() : <div></div>}
              <div className="form-row justify-content-center mt-3">
                <ReCAPTCHA
                  sitekey={KEY}
                  onChange={this.captchaOnChange}
                  onExpired={this.captchaonExpired}
                />
              </div>
              <div className="form-row justify-content-center mt-3">
                <div className="col-md-4 col-6">
                  <button className="CetForm_btn" type="reset">
                    <div className="row m-0 CetForm_btnClear justify-content-start align-items-center">
                      <div
                        className="col-3 m-0 p-0"
                        style={{ borderRight: "2px solid #147cb8" }}
                      >
                        <img
                          src={clearbtn}
                          alt="close"
                          className="CetForm_cImg"
                        />
                      </div>
                      <div className=" col-8 input-group-append ">
                        <span className="input-group-text CetForm_btnC">
                          Clear Form
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="col-md-4 col-6">
                  <button className="CetForm_btn" type="submit">
                    <div className="row m-0 CetForm_btnPR justify-content-start align-items-center">
                      <div
                        className="col-3 m-0 p-0"
                        style={{ borderRight: "2px solid white" }}
                      >
                        <img
                          src={btnPredict}
                          alt="close"
                          className="CetForm_PRImg"
                        />
                      </div>
                      <div className=" col-9 m-0 p-0 input-group-append ">
                        <span className="input-group-text CetForm_btnPRtext">
                          Predict Results
                        </span>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr />
        <div id="JeeResults">
          {this.state.loading ? (
            <Fragment>
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="spinner-border resultSpinner mr-5"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
                <span>Loading Results...</span>
              </div>
              <hr />
            </Fragment>
          ) : null}
          {this.state.showResults ? (
            <Fragment>
              <div className="row JeeForm_row justify-content-center">
                <div className="col-10 Form_messageBorder row JeeForm_row">
                  <div className="col-8 col-md-10 pl-0 pr-0 TopNugde_text">
                    <p>
                      Confused about the admission process and document
                      requirements? Don’t worry we are there for your help!
                    </p>
                  </div>
                  <div className="col-4 col-md-2 justify-content-center">
                    <a href="/admissionconsulting/">
                      <button className="KnowMore_Btn">Know More</button>
                    </a>
                  </div>
                </div>
              </div>
              <p className="mt-2 tableText">
                Predicted Cutoff Ranks are for Final Admission Round
              </p>
              <small className="mt-2  mr-1 mobileText">
                Scroll table for more information. <FaArrowRight />
              </small>
              {this.ShowResultsTable(this.state.results)}
              <hr />
            </Fragment>
          ) : (
            <div>
              {this.state.showError ? (
                <Fragment>
                  <div className="row JeeForm_row justify-content-center">
                    <div className="col-10 Form_messageBorder row JeeForm_row">
                      <div className="col-8 col-md-10 pl-0 pr-0 TopNugde_text">
                        <p>
                          Confused about the admission process and document
                          requirements? Don’t worry we are there for your help!
                        </p>
                      </div>
                      <div className="col-4 col-md-2 justify-content-center">
                        <a href="/admissionconsulting/">
                          <button className="KnowMore_Btn">Know More</button>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center CetForm_row">
                    <div
                      className="alert alert-warning mt-3 col-5"
                      role="alert"
                    >
                      Please Try Again Later.{" "}
                    </div>
                  </div>
                </Fragment>
              ) : null}
            </div>
          )}
        </div>
        <div
          className="row align-items-center justify-content-center CetForm_row "
          id="VideoRow"
        >
          <div className="col-12 col-md-5 text-left Form_textPara">
            <p className="">
              Know your chances and cutoff Ranks of prestigious colleges like
              COEP, VJTI, SPCE, SPIT, PICT, WCE, and many more.
            </p>
            <p>
              Get Rank for all Categories (Open, SC, ST, OBC, NT, EWS, TFWS,
              DEF, ORPHAN, PWD) and Gender.
            </p>
            <p>
              Our Algorithm smartly displays Home University cutoffs for your
              selected region and Other University cutoffs of other regions.
            </p>
            <p>
              Personalize your results with your Branch Preference and College
              type.
            </p>
            <p>All the Best for your Career!</p>
            <h2
              style={{
                color: "rgb(12, 12, 98)",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              Check out our other College Predictors:
            </h2>
            <a className="Forms_links" href="/jee-main-college-predictor/">
              JEE Mains College Predictor
            </a>
            <br />
            <a className="Forms_links" href="/jee-advanced-college-predictor/">
              JEE Advanced College Predictor
            </a>
          </div>
          <div className="col-12 col-md-5">
            <iframe
              className="Form_video"
              src="https://www.youtube.com/embed/aX_sCcjz5Hk"
              title="YouTube video player"
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
        <hr className="mt-2 mb-2" />
        {this.state.modalIsOpen ? (
          <MessageModal closeModal={this.closeModal} modalIsOpen={true} />
        ) : null}
        <Footer className="mt-0" />
      </div>
    );
  }
}
