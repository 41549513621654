export const getAmount = (num) => {
  if (num === "1") {
    return 250;
  }
  if (num === "2") {
    return 300;
  }
  if (num === "3") {
    return 400;
  }
};
