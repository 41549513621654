import React, { Component } from "react";
import "./Footer.css";
import ModalTerms from "./ModalTerms";
import ModalPrivacyPolicy from "./ModalPrivacyPolicy";
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpenPP: false,
      modalIsOpenT: false,
    };
  }
  closeModal = () => {
    this.setState({ modalIsOpenPP: false });
  };
  openModal = () => {
    this.setState({ modalIsOpenPP: true });
  };
  closeModalTerms = () => {
    this.setState({ modalIsOpenT: false });
  };
  openModalTerms = () => {
    this.setState({ modalIsOpenT: true });
  };
  render() {
    return (
      <div className="row Footer_Div justify-content-center">
        <p className="Footer_text">
          Copyright &copy;2022 Graduhat. All Rights Reserved.
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <button onClick={this.openModal} className="Footer_btn" type="button">
            Privacy Policy
          </button>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <button
            onClick={this.openModalTerms}
            className="Footer_btn"
            type="button"
          >
            Terms and Conditions
          </button>
        </p>
        {this.state.modalIsOpenPP ? (
          <ModalPrivacyPolicy
            closeModal={this.closeModal}
            modalIsOpenPP={true}
          />
        ) : null}
        {this.state.modalIsOpenT ? (
          <ModalTerms closeModal={this.closeModalTerms} modalIsOpenT={true} />
        ) : null}
      </div>
    );
  }
}
