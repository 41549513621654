import React, { Component } from "react";
import Navbar from "../Navbar/Navbar";
import "./NewsAll.css";
import { Helmet } from "react-helmet-async";
import graphic1 from "../../Images/graphic1.png";

export default class NewsAll extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta name="robots" content="index,follow" />
        </Helmet>
        <Navbar isForm={true} />

        <div className="row NewsAll_row justify-content-center">
          <div className="col-10">
            <a href="/">HomePage</a>
          </div>
        </div>
      </div>
    );
  }
}
