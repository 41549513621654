import React, { Component, Fragment } from "react";
import "./AdmissionConsultingHomepage.css";
import chat from "../../Images/chat.png";
import experts from "../../Images/experts.png";
import videocalling from "../../Images/video-calling.png";

export default class AdmissionConsulting extends Component {
  render() {
    return (
      <Fragment>
        <div className="row justify-content-center AdmissionConsultingHomepage_row">
          <div className="col-12 col-md-10  text-center">
            <h2 className="AdmissionConsultingHomepage_headerText">
              Admission Consulting
            </h2>
            <p className="text-center AdmissionConsultingHomepage_subheaderText">
              Personalised Admission Consulting to help you select best possible
              colleges based on college’s rank, placements, fees, reputation,
              branch, your location preference, etc.
            </p>
            <div className="row justify-content-center">
              <div className="col-8 col-md-3 AdmissionConsultingHomepage_card ">
                <img
                  src={chat}
                  className="AdmissionConsultingHomepage_icon img-fluid"
                  alt="chat"
                />
                <p className="AdmissionConsultingHomepage_cardHeading">
                  Unlimited Chat Support
                </p>
                <p className="AdmissionConsultingHomepage_cardSubHeading">
                  Get unlimited chat support for all your admission related
                  queries until your last admission round
                </p>
              </div>
              <div className="col-8 col-md-3 AdmissionConsultingHomepage_card">
                <img
                  src={videocalling}
                  className="AdmissionConsultingHomepage_icon img-fluid"
                  alt="video calling"
                />
                <p className="AdmissionConsultingHomepage_cardHeading">
                  One-to-one video call session
                </p>
                <p className="AdmissionConsultingHomepage_cardSubHeading">
                  Get one-to-one video call consultation regarding admission
                  process, college selection, documents, etc.
                </p>
              </div>
              <div className="col-8 col-md-3 AdmissionConsultingHomepage_card">
                <img
                  src={experts}
                  className="AdmissionConsultingHomepage_icon img-fluid"
                  alt="experts"
                />
                <p className="AdmissionConsultingHomepage_cardHeading">
                  Consult with experts
                </p>
                <p className="AdmissionConsultingHomepage_cardSubHeading">
                  Our experts have 20+ years of experience in education and
                  admission field.
                </p>
              </div>
            </div>
            <a href="/admissionconsulting/" className="CollegePredictor_links">
              <button className="AdmissionConsultingHomepage_kmButton">
                Know More
              </button>
            </a>
          </div>
        </div>
        <hr />
      </Fragment>
    );
  }
}
