import axios from "axios";
const baseUrl = "";
export const fetchAllNews = async () => {
  return await axios
    .get(baseUrl + "fetchAllNews.php")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

export const fetchLatestNews = async () => {
  return await axios
    .get(baseUrl + "fetchLatestNews.php")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

export const fetchJeeAdv = async (data) => {
  return await axios
    .post(baseUrl + "fetchJEEAdv.php", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

export const fetchJEEMain = async (data) => {
  return await axios
    .post(baseUrl + "fetchJEEMain.php", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

export const fetchCET = async (data) => {
  return await axios
    .post(baseUrl + "fetchCET.php", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};
export const verifyPlan = async (data) => {
  return await axios
    .post(baseUrl + "verify.php", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

export const login = async (data) => {
  return await axios
    .post(baseUrl + "login.php", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

export const createOrder = async (data) => {
  return await axios
    .post(baseUrl + "pay.php", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

export const verifyEmail = async (data) => {
  return await axios
    .post(baseUrl + "verifyemail.php", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};

export const verifyCaptcha = async (data) => {
  return await axios
    .post(baseUrl + "verifyC.php", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return { error: err };
    });
};
