import React, { Component, Fragment } from "react";
import "./Login.css";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet-async";
import Navbar from "../Navbar/Navbar";
import { login } from "../../DataServices/DataServices";
import { withRouter } from "react-router-dom";
import loginImage from "../../Images/login.png";
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { history } = this.props;
    var formData = new FormData(e.target);
    const data = new FormData();
    data.append("email", formData.get("email") || this.state.email);
    data.append("password", formData.get("password") || this.state.password);
    login(data)
      .then((response) => {
        if (response.error) {
          alert(response.error);
        } else {
          if (!response.data || response.data.length !== 5) {
            alert(
              "Wrong Email Id or Password.Please try again later or contact us at support@graduhat.com"
            );
          } else {
            history.replace("/dashboard", response?.data);
          }
        }
      })
      .catch((error) => {
        alert("Internal Error. Please try again later.");
      });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <meta name="robots" content="index,follow" />
          <title>Login</title>
        </Helmet>
        <Navbar isForm={true} />
        <div className="row LoginPage_row LoginPage_div justify-content-center">
          <div className="col-11 col-md-7">
            <div className="row LoginPage_row LoginPage_box mt-5 justify-content-center">
              <div className="col-12 col-md-6 text-left">
                <h4 className="LoginPage_headerText">Login</h4>
                <form
                  id="plan_form"
                  onSubmit={this.handleSubmit}
                  className="text-left"
                >
                  <div className="form-row  mt-3 mt-md-4 align-items-start justify-content-center">
                    <div className="col-12 col-md-11">
                      <label htmlFor="firstName">
                        Your Registered Email
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="email"
                        name="email"
                        id="email"
                        value={this.state.email}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row  mt-3 mt-md-4 align-items-start justify-content-center">
                    <div className="col-12  mt-md-0 col-md-11  ">
                      <label htmlFor="lastName">
                        Password<span className="text-danger">*</span>
                      </label>
                      <input
                        onChange={this.handleChange}
                        type="password"
                        name="password"
                        id="password"
                        value={this.state.password}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row mt-4 mt-md-4 justify-content-center">
                    <button type="submit" className="LoginPage_btn">
                      Login
                    </button>
                  </div>
                </form>
                <div className="mt-4">
                  <p>
                    Do not have an account?{" "}
                    <a className="LoginPage_links" href="/admissionconsulting">
                      Select Plan
                    </a>{" "}
                    to create an account
                  </p>
                  <p>
                    Forgot your password? Email as at{" "}
                    <a
                      className="LoginPage_links"
                      href="mailto:support@graduhat.com"
                    >
                      support@graduhat.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src={loginImage}
                  alt="Graduhat Login"
                  className="Login_image img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(LoginPage);
