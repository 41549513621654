import React, { Component } from "react";
import Modal from "react-modal";
import cancel from "../../Images/cancel.png";
import "./Modal.css";

Modal.setAppElement("body");
export default class ModalTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.modalIsOpenT) {
      return (
        <div>
          <Modal
            shouldCloseOnOverlayClick={true}
            isOpen={this.props.modalIsOpenT}
            onRequestClose={this.props.closeModal}
            className="ModalT_content"
            contentLabel="Example Modal"
          >
            <div className="row ml-0 mr-0 mt-3 justify-content-center">
              <div className="col-md-10 col-9 text-left">
                <div className="ModelT_header">Disclaimer</div>
              </div>
              <div className="col-md-2 col-3">
                <button
                  onClick={this.props.closeModal}
                  className="ModelT_closeBtn"
                >
                  <img src={cancel} alt="close" className="ModelT_closeImg" />
                </button>
              </div>
            </div>
            <hr className="mt-2" />
            <div className="ModalPara_div text-left">
              <p>
                The following are the terms and conditions of accessing or using
                any of our website, mobile site, etc. (hereinafter “Platforms”)
                developed and operated by Graduhat.
              </p>
              <p>
                Your use of these Platforms at any time constitutes a binding
                agreement by you to abide by these Terms and conditions whether
                or not you become a registered user of the services. The Terms
                and Conditions and Privacy Policy as amended by us from time to
                time govern your use of our Platforms. You acknowledge and agree
                that, by accessing or using our Platforms, you are indicating
                that you have read and that you understand and agree to be bound
                by these terms and receive our services.
              </p>
              <p>
                We may, at our sole discretion, modify or revise these Terms and
                Conditions and Privacy Policy at any time. You should check the
                Terms and Conditions from time to time to ensure that you are
                aware of any revisions/modifications and you are familiar with
                the current version of the Terms of Use of our Platforms /
                Products / Services.
              </p>
              <ol>
                <li>
                  <b>Eligibility</b>
                  <ol className="Modal_ol">
                    <li className="ModalMargin_top">
                      1.1) By accessing or using the Platforms You represent and
                      warrant that You fulfil the requirement of minimum age and
                      have the legal capacity and authority to enter into a
                      contract as per the applicable laws.
                    </li>
                    <li className="ModalMargin_top">
                      1.2) If You are under the age of 18 or the age of majority
                      in Your jurisdiction, You must use the Platforms under the
                      supervision of Your parent, legal guardian or a
                      responsible adult.
                    </li>
                    <li className="ModalMargin_top">
                      1.3) You cannot register on our Platforms if you are less
                      than 13 years old. Graduhat, its affiliates, its owners,
                      its partners (hereinafter “We”) do not intentionally
                      collect, record or maintain personal information of those
                      who are under 13 years of age.
                    </li>
                  </ol>
                  <li className="ModalMargin_top">
                    <b>Registration</b>
                    <ol className="Modal_ol">
                      <li className="ModalMargin_top">
                        2.1) You agree that any information you provide us about
                        yourself after registration or at any other time is
                        true, accurate, up-to-date and complete. We reserve the
                        right to terminate your membership and access to our
                        Platforms if We learn that you have provided false or
                        misleading information.
                      </li>
                      <li className="ModalMargin_top">
                        2.2) By registering, you agree to make your contact
                        details available to receive promotional communication
                        (emails/SMS/WhatsApp/others) from the Platforms.
                      </li>
                      <li className="ModalMargin_top">
                        2.3) When you register to use our Platforms, you will be
                        asked to create a password. You agree not to divulge
                        your password to any other person/entity and take
                        complete responsibility to maintain its confidentiality.
                        We will not be responsible for any liabilities (in part
                        or full) arising in connection with the use (or misuse)
                        of your password; regardless of whether such use or
                        misuse is with or without your knowledge or consent. If
                        you suspect that someone else knows or is using your
                        password, you must notify us immediately by emailing us
                        at{" "}
                        <a href="mailto:support@graduhat.com">
                          support@graduhat.com
                        </a>
                        .
                      </li>
                    </ol>
                  </li>
                </li>
                <li className="ModalMargin_top">
                  <b>Use of our Platforms</b>
                  <ol className="Modal_ol">
                    <li className="ModalMargin_top">
                      3.1) You agree that the following actions are prohibited
                      <ol type="a">
                        <li>
                          Copying, reproducing, republishing, distributing,
                          transmitting, extracting, downloading, sharing,
                          displaying, selling, or otherwise exploiting any
                          content, data, information, photos, images, logos,
                          graphics, sounds, videos on the Platforms or any other
                          products or services provided by the Graduhat in any
                          form or by any means including, but not limited to,
                          electronic, mechanical, photocopying, recording, or
                          otherwise, without the prior express written
                          permission of Graduhat.
                        </li>
                        <li>
                          Gaining or attempting to gain unauthorised access to
                          any portion of the Platforms which is not intended for
                          your use.
                        </li>
                        <li>
                          Using any software or automated programs or any
                          process to access, navigate, copy, download, crawl,
                          scrape or extract by any manner any data, content
                          information from the Platforms
                        </li>
                        <li>
                          Reverse engineering, decompiling, deciphering or
                          deriving the source code for the Platforms.
                        </li>
                        <li>
                          Impersonating any other person or entity or making any
                          misrepresentation of the information shared about you.
                        </li>
                        <li>
                          Stalking, threatening, spamming or in any manner
                          harassing any other affiliate or user of the
                          Platforms.
                        </li>
                      </ol>
                    </li>
                    <li className="ModalMargin_top">
                      3.2) Unlimited chat support is a paid service and is valid
                      till the last admission/counselling round of your
                      specified examination for academic year 2022-23.
                    </li>
                    <li className="ModalMargin_top">
                      3.3) Number of video-calls in admission consulting
                      services are limited, as mentioned in your subscription
                      plan.
                    </li>
                  </ol>
                </li>
                <li className="ModalMargin_top">
                  <b>Intellectual Property Rights</b>
                  <ol className="Modal_ol">
                    <li className="ModalMargin_top">
                      4.1) All the content, material, Graduhat’s trademark,
                      Graduhat’s service mark, Graduhat’s trade name, software,
                      text, script, videos, audio, etc. contained on the
                      Platforms, products, services are Intellectual Property of
                      Graduhat and protected by the governing laws and
                      regulations in India.
                    </li>
                    <li className="ModalMargin_top">
                      4.2) All the images, graphics, logos, icons created,
                      developed, owned by Graduhat are Intellectual Property of
                      Graduhat. 
                    </li>
                    <li className="ModalMargin_top">
                      4.3) All other trademarks, service marks, logos, images,
                      graphics and icons used on the Platforms are Intellectual
                      Property of their respective owners such as educational
                      service providers, organizations, third party websites,
                      third party providers etc.
                    </li>
                    <li className="ModalMargin_top">
                      4.4) We respect intellectual property rights of any third
                      party. If We receive any communication with regard to any
                      possible infringement of any intellectual property right
                      of any third party, We shall remove the infringing content
                      from the Platforms.
                    </li>
                  </ol>
                </li>
                <li className="ModalMargin_top">
                  <b>Payment terms</b>
                  <ol className="Modal_ol">
                    <li className="ModalMargin_top">
                      5.1) Amount payable towards any of the paid services like
                      admission consulting services, will be displayed to the
                      user before he/she makes payment. Unless otherwise
                      expressly mentioned, full payments towards any of the
                      products or services offered by Graduhat shall be made
                      before accessing the services. It is the sole
                      responsibility of the user purchasing a product or service
                      on our Platforms to assess the suitability and relevance
                      of the product or service proposed to be purchased.
                    </li>
                    <li className="ModalMargin_top">
                      5.2) The payment for service once subscribed to by you is
                      not refundable and any amount paid shall stand
                      appropriated. Refunds, if any, will be at the sole
                      discretion of Graduhat.
                    </li>
                    <li className="ModalMargin_top">
                      5.3) We generate system generated invoice with regard to
                      the products or services purchased on our Platforms. You
                      hereby irrevocably accept to receive the invoice as a soft
                      copy through an email.
                    </li>
                    <li className="ModalMargin_top">
                      5.4) To purchase any services or products offered through
                      our Platforms, you must have internet access and a current
                      valid accepted payment method as indicated during sign-up
                      ("payment method"). We do not store any of your
                      credit/debit card/bank account/other payment method
                      information or such other information restricted by the
                      Reserve Bank of India (RBI) for processing payment and
                      have partnered with payment gateways for the payment
                      towards the services. By using a third party payment
                      provider, you agree to abide by the terms of such a
                      payment provider. You agree that in case Graduhat’s third
                      party payment provider stores any such information, We
                      will not be responsible for such storage, and it will be
                      solely at your discretion to allow the third party to
                      store such information. Any loss of such information or
                      any loss incurred by you due to the usage of such
                      information will be solely a loss incurred by you, and We
                      are in no way liable for any such losses and are neither
                      responsible to reimburse / make good such losses in any
                      manner whatsoever. You also agree to pay the applicable
                      fees for the payments made through the Platforms.
                    </li>
                    <li className="ModalMargin_top">
                      5.5) Users buying the products/services online are
                      redirected to third party gateways for completing payment
                      transactions. These transactions happen on a third party
                      network and hence are not controlled by Graduhat.
                    </li>
                    <li className="ModalMargin_top">
                      5.6) In the event of any suspension or termination of
                      services on account of non-compliance of these Terms of
                      Use, any payment made to the Company by you shall stand
                      forfeited with immediate effect.
                    </li>
                  </ol>
                </li>
                <li className="ModalMargin_top">
                  <b>Indemnification</b>
                  <p className="ModalMargin_top">
                    You agree to pay for or make good any losses or damages that
                    may occur to Graduhat, its employees, its contractors, its
                    licensors, its third party suppliers, its partners, its
                    owners, its affiliates from  direct, indirect, incidental,
                    special, consequential or exemplary damages arising out of,
                    relating to, or resulting from your use of the Platforms
                    including but not limited to information provided by you or
                    any other matter relating to the Platforms, infringement of
                    intellectual property rights, any violation of the Terms or
                    any breach of the representations, warranties made herein.
                  </p>
                </li>
                <li className="ModalMargin_top">
                  <b>Warranties and Disclaimer</b>
                  <ol className="Modal_ol">
                    <li className="ModalMargin_top">
                      7.1) We make reasonable efforts to ensure that the
                      Platforms and our services are safe to use and accurate.
                      You expressly understand and agree that your use of the
                      Platforms, content, products and services is at your sole
                      risk. All content and material on the Platforms are
                      provided on an AS IS and AS AVAILABLE basis. We expressly
                      disclaim all warranties and conditions of any kind whether
                      expressed or implied.
                    </li>
                    <li className="ModalMargin_top">
                      7.2) Though the website and our services have been
                      designed with utmost care, yet due to some inevitable
                      error quotient in human processing, the graphics, texts,
                      documents etc. may have some typographical or technical
                      errors which will be removed or edited from time to time
                      as the website/services will be updated. You understand
                      and agree that We do not guarantee the accuracy, relevancy
                      or completeness of any information in or provided on or
                      from our Platforms and services. In no unprecedented or
                      already occurred event, We shall not bear any liability to
                      any party or individual for any Direct/Indirect, Special
                      or other Consequential Damages or Losses pertaining to the
                      usage of our services, website, information on them or any
                      other hyperlinked website including but not limited to any
                      limitation, lost profits, interruption of business,
                      program loss or any other data loss.
                    </li>
                    <li className="ModalMargin_top">
                      7.3) We make no warranty that
                      <ol type="a">
                        <li>
                          the site and the services are uninterrupted, secure,
                          accurate, timely, virus free and error free
                        </li>
                        <li>
                          the site and the services will meet your requirements
                        </li>
                        <li>
                          the content and services are uninterrupted and virus
                          free
                        </li>
                        <li>
                          the results that are obtained from the use of the
                          Platforms including from consulting products and
                          services are accurate and reliable
                        </li>
                        <li>
                          any errors in the products and services will be
                          corrected
                        </li>
                      </ol>
                    </li>
                    <li className="ModalMargin_top">
                      7.4) We expressly disclaim warranties of any kind for any
                      use of or any access to the Platforms, material, services,
                      information, links, or content presented on its Platforms,
                      external website linked thereto, and to any material,
                      information (including any software, products, or
                      services), links, or content linked thereto including the
                      implied warranties of merchantability and fitness for a
                      particular purpose, and non-infringement. Graduhat has no
                      control over any external website or over any external
                      material, information, links, and content linked to its
                      Platforms. There are no guarantees and no warranties of
                      online availability, impressions, and click-throughs. The
                      entire risk as to the performance of, or non-performance
                      of, or arising out of the use of, or the access of, or the
                      lack of access to our Platforms, any material,
                      information, links, or content presented on the Platforms
                      to any external website linked thereto, or to any external
                      material, information, links, or content linked thereto,
                      is borne by the user, visitor, customer, or other person.
                    </li>
                    <li className="ModalMargin_top">
                      7.5) The college predictions should be used as a guiding
                      pointer for students in choosing their best available
                      college options. We do not guarantee the college
                      predictions as it may change based on certain parameters &
                      circumstances for the specific academic year. We are not
                      liable for any mismatch in college allocation/admission &
                      prediction.
                    </li>
                    <li className="ModalMargin_top">
                      7.6) Your subscription for admission consulting services
                      will be terminated at the end of the last
                      admission/counselling round of your specified examination
                      for academic year 2022-23.
                    </li>
                    <li className="ModalMargin_top">
                      7.7) It is the sole responsibility of the registered user
                      to make use of the total video call session/sessions
                      available to them in their plan. We will not be
                      responsible for any unused/unutilised video call
                      session/sessions. The subscription for admission
                      consulting services will be terminated at the end of the
                      last admission/counselling round of your specified
                      examination for academic year 2022-23 irrespective of
                      whether all the video call session/sessions available to
                      them in their plan are used/utilised or not.
                    </li>
                    <li className="ModalMargin_top">
                      7.8) The video call session will be scheduled as per
                      availability of our experts/partners/executives.
                    </li>
                    <li className="ModalMargin_top">
                      7.9) The maximum duration of a single video call session
                      would be limited to 40 mins.
                    </li>
                    <li className="ModalMargin_top">
                      7.10) The word ‘unlimited’ in the ‘unlimited chat support’
                      indicates to unlimited queries that a registered user can
                      ask regarding his/her admission process of the specific
                      exam mentioned while registering for our services.
                      Unlimited does not indicate to 24 X 7 chat support.
                      <b>
                        {" "}
                        The availability of the chat support will be as per
                        availability of our experts/partners/executives.
                      </b>
                    </li>
                    <li className="ModalMargin_top">
                      7.11) If you are making or planning to make any decision,
                      whether personal or business decisions, based on the
                      consulting services or the content on the site, you should
                      conduct an independent verification before making your
                      important decisions.
                    </li>
                  </ol>
                </li>
                <li className="ModalMargin_top">
                  <b>Limitation of Liability</b>
                  <ol className="Modal_ol">
                    <li className="ModalMargin_top">
                      8.1) In no event and under no circumstances and under no
                      legal theory, tort, contract, or otherwise shall Graduhat,
                      its affiliates, directors, officers, employees, partners,
                      advertisers, licensors, suppliers or agents without
                      limitation be liable to you or any third party for any
                      special, incidental, indirect, consequential, punitive or
                      exemplary damages whatsoever, including those resulting
                      from loss of use, data or profits or any other claim
                      arising out, of or in connection with, your use of, or
                      access to the Platforms and services.
                    </li>
                    <li className="ModalMargin_top">
                      8.2) We bear no responsibility for any accident, injury or
                      loss of life to any individual or group of people
                      resulting from accessing our consulting services or
                      information on our website.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </Modal>
        </div>
      );
    } else {
      this.props.closeModal();
      return null;
    }
  }
}
