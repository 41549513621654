import React, { Component } from "react";
import Modal from "react-modal";
import cancel from "../../Images/cancel.png";
import "./Modal.css";

Modal.setAppElement("body");
export default class ModalPrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpenPP: false,
    };
  }

  render() {
    if (this.props.modalIsOpenPP) {
      return (
        <div>
          <Modal
            shouldCloseOnOverlayClick={true}
            isOpen={this.props.modalIsOpenPP}
            onRequestClose={this.props.closeModal}
            className="ModalPP_content"
            contentLabel="Example Modal"
          >
            <div className="row ml-0 mr-0 mt-3 justify-content-center">
              <div className="col-md-10 col-9 text-left">
                <div className="ModelPP_header">Privacy Policy</div>
              </div>
              <div className="col-md-2 col-3">
                <button
                  onClick={this.props.closeModal}
                  className="ModelPP_closeBtn"
                >
                  <img src={cancel} alt="close" className="ModelPP_closeImg" />
                </button>
              </div>
            </div>
            <hr className="mt-2" />
            <div className="ModalPara_div text-left">
              <p>
                We, at Graduhat, are committed to respecting your online privacy
                and recognize your need for appropriate protection and
                management of any "Personal Information" you share with us. You
                can be assured that any information provided by you will only be
                used in accordance with this privacy statement. By using our
                website or mobile site (hereinafter “Platforms”), you indicate
                that you understand, agree and consent to this Privacy Policy.
                This Policy is subject to the ‘Terms and Conditions’ of the
                Platforms.
              </p>
              <p>
                Graduhat, its owners, its affiliates (herein after “We”) may, at
                our sole discretion, modify or revise this Privacy Policy at any
                time. You should check the Privacy Policy from time to time to
                ensure that you are aware of any revisions/modifications and you
                are familiar with the current version of the Privacy Policy.
              </p>
              <ol>
                <li>
                  <b>The types of Personal Information that We collect</b>
                  <p className="ModalMargin_top">
                    Services such as Admission Consulting Services require you
                    to register on graduhat.com. At the time or after the
                    registration, We will ask for your personal and educational
                    information to create your account and provide you with
                    better service.
                  </p>
                </li>
                <ol type="a">
                  <li>
                    <b>Registration</b>
                  </li>
                  <p>
                    We may collect the following information to register you on
                    our Platforms to allow you to use our products and/or avail
                    the various services We offer:
                  </p>
                  <ol type="i">
                    <li>
                      Personal Information such as Name and Date of Birth.
                    </li>
                    <li>
                      Contact information such as email address and phone number
                    </li>
                    <li>Demographic information such as City.</li>
                  </ol>
                  <li className="ModalMargin_top">
                    <b>Consulting Services</b>
                  </li>
                  <p>
                    After purchasing of Admission Consulting plans, We may ask
                    for additional information such as:
                  </p>
                  <ol type="i">
                    <li>Your City and State</li>
                    <li>
                      Information pertaining to racial or ethnic origin,
                      religion etc. (The only reason to collect such information
                      is to provide better college suggestions to students, as
                      students may get benefitted from the seat reservations in
                      colleges/educational institutes for students belonging to
                      a particular ethnic/racial/religious origin.)
                    </li>
                    <li>Your score, rank in various exams.</li>
                  </ol>
                </ol>
                <li className="ModalMargin_top">
                  <b>Use of your personal Information</b>
                  <ol className="Modal_ol">
                    <li className="ModalMargin_top">
                      2.1 We store your personal information to
                      <ol type="i">
                        <li>provide personalised and better services, </li>
                        <li>
                          enhance products, functionalities and services offered
                          by us,
                        </li>
                        <li>
                          recommend the ‘best fit college’ with the information
                          provided by you,
                        </li>
                        <li>
                          send important notification and/or messages through
                          email/SMS/WhatsApp if any.
                        </li>
                      </ol>
                    </li>
                    <li className="ModalMargin_top">
                      2.2 We do not share any of your personal data/information
                      with any third party company/vendors/personnel.
                    </li>
                  </ol>
                </li>
                <li className="ModalMargin_top">
                  <b>Cookies</b>
                  <p className="ModalMargin_top">
                    Graduhat does not store/set/use any cookies of its own to
                    collect any user information. We have partnered with third
                    party services such as Google Analytics, Google Ad Manager,
                    tawk.to to provide better and personalised services. These
                    third party services may use cookies to collect user
                    information. By visiting, using our Platforms and services
                    you agree to allow these third part services to
                    store/set/use cookies on your device/browser.
                  </p>
                  <ol type="a">
                    <li>
                      <b>Google Analytics</b>
                      <p>
                        Graduhat uses Google Analytics to help create reports
                        and statistics on the performance, usage and visitor
                        engagement of the website. Google Analytics may collect
                        information such as your IP address, device type and
                        operating system, referring URLs, location, pages
                        visited, etc. These cookies collect information in such
                        a way that does not identify anyone as an individual.
                        Google Analytics has its own privacy policy which you
                        can review here. We have no influence or control on how
                        google uses your data.
                      </p>
                    </li>
                    <li>
                      <b>Tawk.to</b>
                      <p>
                        Graduhat uses tawk.to to provide chat widget to our
                        consulting service subscribers. Tawk.to uses cookies to
                        track unique visitors in order to retrieve their unique
                        chat messages, to manage visitor connections, to manage
                        visitor sessions, to remember your preferences etc.
                        These cookies do not store your personal information.
                        Tawk.to has its own cookie policy which you can review
                        here. We have no influence or control on how tawk.to
                        uses your data.
                      </p>
                    </li>
                    <li>
                      <b>Google AdSense</b>
                      <p>
                        We may use Google AdSense to display ads on some of our
                        pages. Google AdSense may collect your information such
                        as IP address, device type and operating system,
                        websites visited, products viewed or purchased,
                        location, behaviour information, demographic details,
                        browsing history and browsing behaviour, other personal
                        preferences, etc. to provide personalised ads. These
                        cookies collect information in such a way that does not
                        identify anyone as an individual. To review how google
                        collects your data click here, to review what data is
                        collected by google click here. You can always control
                        the data collected by Google by following information
                        mentioned on the articles linked above. Google AdSense
                        has its own privacy policy which you can review here. We
                        have no influence or control on how google uses your
                        data.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </Modal>
        </div>
      );
    } else {
      this.props.closeModal();
      return null;
    }
  }
}
